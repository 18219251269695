import { useEffect, useState } from 'react';
import { addPost, getPostAdmin } from '../../helpers/apis';
import { IsLogin } from '../../helpers/isLogin';
import { CSVLink } from "react-csv";
import { IoMdDownload } from "react-icons/io";
import { Form, Row, Col, FloatingLabel, Button, Modal } from 'react-bootstrap';


function AddPosts() {
    const [authToken, setAuthToken] = useState(window.localStorage.getItem("token"))
    const [show, setShow] = useState(false);
    const [user, setUser] = useState({
        id: ""
    })

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [data, setData] = useState({
        userID: "",
        PostType: "",
        poster: "",
        post: "",
        tweet: "",
        creator: "",
    })

    async function submit() {
        const myForm = new FormData();
        myForm.set("userID", data.userID);
        myForm.set("PostType", data.PostType);
        myForm.set("poster", data.poster);
        myForm.set("post", data.post);
        myForm.set("tweet", data.tweet);
        myForm.set("creator", data.creator);
        myForm.set("YoutubeType", data.YoutubeType);

        const res = await addPost(myForm);
        alert(res.message)
    }

    const [csv, setCSV] = useState([])
    const CSV_Date = async () => {
        const res = await getPostAdmin(`limit=1000000000`);
        setCSV(res.message)
    }

    useEffect(() => {
        const __user = IsLogin(authToken);
        setData({ ...data, userID: __user != "" ? (JSON.parse(__user)).id : "" })
        CSV_Date()
    }, [])


    return (
        <>
            <div style={{ width: "100%", display: "flex", justifyContent: "end", flexDirection: "row-reverse" }}>
                <CSVLink

                    filename={"posts.csv"}
                    className="btn green_button mx-2"
                    style={{ height: "38px", padding: "5px 10px", borderRadius: "5px", fontWeight: 500 }}
                    data={(csv).map((item) => {
                        return {
                            username: item.userID.username,
                            profile: item.userID.profile,
                            Poster: item.Poster,
                            PostType: item.PostType,
                            tweet: item.tweet,
                            creator: item.creator,
                            status: item.status,
                            timestamp: item.timestamp,
                        }
                    })}>
                    <IoMdDownload />
                </CSVLink>

                <button className='btn btn-dark' onClick={handleShow}>Add Posts</button>
            </div>

            <Modal show={show} onHide={handleClose} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>Add Posts</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <Form encType='multipart/form-data'>
                        <Row className='my-4'>
                            <Col className='visually-hidden'>
                                <FloatingLabel controlId='formGroupUserID' label='User ID'>
                                    <Form.Control
                                        className='p-3'
                                        value={data.userID}
                                        onChange={(e) => setData({ ...data, "userID": e.target.value })}
                                        placeholder='User ID'
                                    />
                                </FloatingLabel>
                            </Col>
                            <Col>
                                <FloatingLabel controlId='formGroupPostType' label='Post Type'>
                                    <Form.Select
                                        className='p-3'
                                        value={data.PostType}
                                        onChange={(e) => setData({ ...data, "PostType": e.target.value, poster: "", post: "" })}
                                    >
                                        <option value={''} disabled>Choose Post Type</option>
                                        <option value='text'>Text Only</option>
                                        <option value='img'>Image with Text</option>
                                        <option value='video'>Video with Text</option>
                                        <option value='vimeo'>Vimeo with Text</option>
                                        <option value='youtube'>Youtube with Text</option>
                                    </Form.Select>
                                </FloatingLabel>
                            </Col>
                        </Row>
                        <Row className='my-4'>
                            {data.PostType !== 'text' &&
                                <>
                                    {(data.PostType === 'video') &&
                                        <Col>
                                            <FloatingLabel controlId='formGroupPoster' label='Poster'>
                                                <Form.Control
                                                    className='p-3'
                                                    type='file'
                                                    accept='image/*'
                                                    onChange={(e) => setData({ ...data, "poster": e.target.files[0] })}
                                                    placeholder='Poster'
                                                />
                                            </FloatingLabel>
                                        </Col>
                                    }
                                    <Col>
                                        {data.PostType === 'img' ?
                                            <FloatingLabel controlId='formGroupImage' label='Image'>
                                                <Form.Control
                                                    className='p-3'
                                                    type='file'
                                                    accept='image/*'
                                                    onChange={(e) => setData({ ...data, "post": e.target.files[0] })}
                                                    placeholder='Post'
                                                />
                                            </FloatingLabel>
                                            : (data.PostType === 'video') ?
                                                <FloatingLabel controlId='formGroupVideo' label='Video'>
                                                    <Form.Control
                                                        className='p-3'
                                                        type='file'
                                                        accept='video/*'
                                                        onChange={(e) => setData({ ...data, "post": e.target.files[0] })}
                                                        placeholder='Post'
                                                    />
                                                </FloatingLabel>
                                                : (data.PostType === 'vimeo') ?
                                                    <FloatingLabel controlId='formGroupVimeo' label='Vimeo'>
                                                        <Form.Control
                                                            className='p-3'
                                                            type='text'
                                                            onChange={(e) => setData({ ...data, "post": e.target.value })}
                                                            placeholder='Video link'
                                                        />
                                                    </FloatingLabel>
                                                    : (data.PostType === 'youtube') ?
                                                        <FloatingLabel controlId='formGroupYoutube' label='Youtube'>
                                                            <Form.Control
                                                                className='p-3'
                                                                type='text'
                                                                onChange={(e) => setData({ ...data, "post": e.target.value })}
                                                                placeholder='Video link'
                                                            />
                                                        </FloatingLabel>
                                                        : ""
                                        }
                                    </Col>
                                </>
                            }
                        </Row>

                        {
                            data.PostType === 'youtube' &&
                            <Row className='my-4'>
                                <Col>
                                    <FloatingLabel controlId='formGroupYoutubeType' label='Youtube Video Type'>
                                        <Form.Select
                                            className='p-3'
                                            value={data.YoutubeType}
                                            onChange={(e) => setData({ ...data, "YoutubeType": e.target.value})}
                                            required
                                        >
                                            <option value={''} disabled selected>Choose Youtube Video Type</option>
                                            <option value='public'>Public</option>
                                            <option value='private'>Private</option>
                                        </Form.Select>
                                    </FloatingLabel>
                                </Col>
                            </Row>
                        }

                        <Row className='my-4'>
                            <Col>
                                <FloatingLabel controlId='formGroupText' label='Text'>
                                    <Form.Control
                                        className='p-3'
                                        value={data.Stop}
                                        onChange={(e) => setData({ ...data, "tweet": e.target.value })}
                                        placeholder='Text'
                                    />
                                </FloatingLabel>
                            </Col>
                        </Row>
                        <Row className='my-4'>
                            <Col>
                                <FloatingLabel controlId='formGroupCreator' label='Creator'>
                                    <Form.Control
                                        className='p-3'
                                        value={data.creator}
                                        onChange={(e) => setData({ ...data, "creator": e.target.value })}
                                        placeholder='Enter creator name'
                                    />
                                </FloatingLabel>
                            </Col>
                        </Row>
                    </Form>

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}> Close </Button>
                    <Button variant="dark" onClick={() => submit()}> Post </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}



export default AddPosts;