import moment from "moment";


export const T2D = (time) => {
    // Convert the timestamp to a date using Moment.js
    const date = moment(time);

    // Format the date as per your requirement (optional)
    const formattedDate = date.format('DD-MM-YYYY HH:mm:ss');
    return formattedDate;
}