import Signal from './signals';
import AddSignal from './addSignal';
import Logo from "../../assets/NEW/logo.png"
import { useEffect, useState } from 'react';
import { IsLogin } from "../../helpers/isLogin"
import { useLocation } from 'react-router';
import AddPosts from './addPosts';
import Posts from './posts';
import Users from './users';
import Subscriptions from './subscritions';
import MonthlyReport from './monthly-report';
import CompleteReport from './complete-report';
import MassEmail from './massEmail';


export function Dashboard({ notify, toast, getUser }) {
    const { hash } = useLocation()
    const [authToken, setAuthToken] = useState(window.localStorage.getItem("token"))
    const [user, setUser] = useState(null)


    useEffect(() => {
        const __user = IsLogin(authToken);

        if (authToken == "") {
            notify("You need to login first", toast.error)
            window.location.href = "/"

        } else if ((JSON.parse(__user)).role != "admin") {
            notify("Only admin can access", toast.error)
            window.location.href = "/"

        } else {
            setUser(__user != "" ? (JSON.parse(__user)) : "")

        }

    }, [])




    if (user != null && user?.role == "admin") {

        return <>
            <header className='d-header'>
                <div>
                    <img src={Logo} className='img-fluid' width={"150px"} alt="Logo" />
                </div>
                <div>
                    <button className="btn btn-dark" onClick={() => { window.localStorage.setItem("token", ""); window.location.href = "/" }}>Sign Out</button>
                </div>
            </header>
            <main className='d'>

                <div className="sidebar-main">
                    <h3>Navigation</h3>
                    <ul>
                        <li>
                            <a href="/">Home</a>
                        </li>
                        <li>
                            <a className={window.location.hash == "" && "active"} href="#">Signals</a>
                        </li>
                        <li>
                            <a className={window.location.hash == "#posts" && "active"} href="#posts">Posts</a>
                        </li>
                        <li>
                            <a className={window.location.hash == "#users" && "active"} href="#users">Users</a>
                        </li>
                        <li>
                            <a className={window.location.hash == "#paid-users" && "active"} href="#paid-users">Subscribed Users</a>
                        </li>
                        <li>
                            <a className={window.location.hash == "#monthly-report" && "active"} href="#monthly-report">Admins Monthly Report</a>
                        </li>
                        <li>
                            <a className={window.location.hash == "#complete-report" && "active"} href="#complete-report">Admins Complete Report</a>
                        </li>
                        <li>
                            <a className={window.location.hash == "#send-emails" && "active"} href="#send-emails">Mass Emails</a>
                        </li>
                        <li>
                            <a href="#" onClick={() => { window.localStorage.setItem("token", ""); window.location.href = "/" }}>Sign Out</a>
                        </li>
                    </ul>
                </div>

                {
                    (hash == "#posts") ?
                        <div className="dbody">
                            <h2 className='heading'>Posts</h2>

                            <div className="content">
                                <div style={{ display: "flex", justifyContent: "end" }}>
                                    <AddPosts />
                                </div>
                                <Posts />
                            </div>
                        </div>
                        :
                        (hash == "#users") ?
                            <div className="dbody">
                                <h2 className='heading'>Users</h2>

                                <div className="content">
                                    <Users user={user} />
                                </div>
                            </div>
                            :
                            (hash == "#paid-users") ?
                                <div className="dbody">
                                    <h2 className='heading'>Subscribed Users</h2>
                                    <div className="content">
                                        <Subscriptions />
                                    </div>
                                </div>
                                :
                                (hash == "#monthly-report") ?
                                    <div className="dbody">
                                        <h2 className='heading'>Admins Monthly Report of Posted Signals</h2>
                                        <div className="content">
                                            <MonthlyReport />
                                        </div>
                                    </div>
                                    :
                                    (hash == "#complete-report") ?
                                        <div className="dbody">
                                            <h2 className='heading'>Admins Complete Report of Posted Signals</h2>
                                            <div className="content">
                                                <CompleteReport />
                                            </div>
                                        </div>
                                        :
                                        (hash == "#send-emails") ?
                                            <div className="dbody">
                                                <h2 className='heading'>Send Emails to All Users</h2>
                                                <div className="content">
                                                    <MassEmail />
                                                </div>
                                            </div>
                                            :
                                            <div className="dbody">
                                                <h2 className='heading'>Signal</h2>

                                                <div className="content">
                                                    <div style={{ display: "flex", justifyContent: "end" }}>
                                                        <AddSignal />
                                                    </div>
                                                    <Signal />
                                                </div>
                                            </div>
                }
            </main>
        </>

    }

}