import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { signup_user } from "../../helpers/apis"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { AuthSlider } from "./slider";

import { useMediaQuery } from 'react-responsive'
import Select from 'react-select'
import countryList from 'react-select-country-list'

import 'react-phone-number-input/style.css'
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';

import { Form, FloatingLabel, Button } from 'react-bootstrap';



export function SignupForm() {
    const navigate = useNavigate()
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 992px)' })

    const [PassShow, setPassShow] = useState(false)
    const [PassShow2, setPassShow2] = useState(false)
    const [countryVal, setCountryVal] = useState("PK")
    const [data, setData] = useState({
        username: "",
        email: "",
        country: "",
        password: "",
        conPassword: "",
        whatsapp: "",
    })


    async function Submit(e) {
        e.preventDefault()

        if (!isValidPhoneNumber(data.whatsapp)) return notify("Invalid Whatsapp number", toast.error)
        if (!data.country) return notify("Invalid Country", toast.error)
        if (data.password.length < 8) return notify("Password is too weak", toast.error)
        if (data.password.length > 20) return notify("Password is too long", toast.error)
        if (data.password !== data.conPassword) return notify("Password not match", toast.error)

        const payload = data


        signup_user(payload)
            .then(_data => {
                if (_data.success) {
                    notify(_data.message, toast.success)
                    return navigate(`/verify-account/${data.email}`)
                }
                if (!_data.success) return notify(_data.message, toast.error)
            })
            .catch(err => {
                console.log(err.response.data);
                if (!data.success) return notify(data.message, toast.error)
            })
    }


    const notify = (response, types) =>
        types(response, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });


    const changeHandler = value => {
        setData({ ...data, country: value.label })
        setCountryVal(value)
        console.log(value);
    }

    const style = {
        control: base => ({
            ...base,
            border: 0,
            // This line disable the blue border
            boxShadow: "none"
        })
    };





    return <>
        <ToastContainer style={{ width: "max-content" }} />

        <div className={`${isTabletOrMobile ? "container-fluid" : "container"}  `}>
            <div className="row">
                <div className="col-12 col-lg-6">

                    <Form className="login" onSubmit={(e) => Submit(e)}>
                        <div className="mb-3 text-center row">
                            <h1 style={{ color: "var(--textPrimaryColor)" }}>Welcome To, <br /> Capital Consultant</h1>
                        </div>

                        <FloatingLabel controlId="username" label="Enter Your Name" className="mb-3 row">
                            <Form.Control
                                type="text"
                                value={data.username}
                                onChange={(e) => setData({ ...data, username: e.target.value })}
                                placeholder="Your Name"
                                required
                            />
                        </FloatingLabel>

                        <FloatingLabel controlId="whatsapp" label="Enter Whatsapp Number" className="mb-3 row">
                            <PhoneInput
                                className="form-control"
                                international
                                countryCallingCodeEditable={false}
                                value={data.whatsapp}
                                onChange={(e) => setData({ ...data, whatsapp: e })}
                                placeholder="Select country"
                            />
                        </FloatingLabel>

                        <FloatingLabel controlId="email" label="Enter Email" className="mb-3 row">
                            <Form.Control
                                type="email"
                                value={data.email}
                                onChange={(e) => setData({ ...data, email: e.target.value })}
                                placeholder="Your Email"
                                required
                            />
                        </FloatingLabel>

                        <FloatingLabel controlId="country" label="Select Country" className="mb-3 row">
                            <div className="form-control" style={{ display: "flex", alignItems: "center", padding: "3px 0px 3px 10px" }} >
                                <Select
                                    isSearchable={true}
                                    options={countryList().getData()}
                                    styles={style}
                                    onChange={changeHandler}
                                    placeholder="Select Country"
                                />
                            </div>
                        </FloatingLabel>

                        <FloatingLabel controlId="password" label="Enter Password" className="mb-3 row ">
                            <Form.Control
                                type={!PassShow ? "password" : "text"}
                                value={data.password}
                                onChange={(e) => setData({ ...data, password: e.target.value })}
                                placeholder="Your Password"
                                required
                            />
                            <span className="icon-eye" data-eye-icon={!PassShow ? "\uf06e" : "\uf070"} onClick={() => setPassShow(!PassShow)}></span>
                        </FloatingLabel>

                        <FloatingLabel controlId="conPassword" label="Enter Confirm Password" className="mb-3 row">
                            <Form.Control
                                type={!PassShow2 ? "password" : "text"}
                                value={data.conPassword}
                                onChange={(e) => setData({ ...data, conPassword: e.target.value })}
                                placeholder="Confirm Password"
                                required
                            />
                            <span className="icon-eye" data-eye-icon={!PassShow2 ? "\uf06e" : "\uf070"} onClick={() => setPassShow2(!PassShow2)}></span>
                        </FloatingLabel>

                        <div className="mb-3">
                            <div className="d-flex justify-content-center">
                                <Button className="green_button" type="submit">Create Account</Button>
                            </div>
                            <div className="my-3 wq24">
                                Already have an account?
                                <a href={"#signin"} style={{ color: "var(--textPrimaryColor)" }}>Log in</a>
                            </div>
                        </div>
                    </Form>

                </div>
                <div className={`${isTabletOrMobile && "d-none"} col col-md-6 col-lg-6 `}>
                    <AuthSlider />
                </div>
            </div>
        </div>
    </>

}