import React, { useRef, useState } from 'react';

// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

import { Pagination, Autoplay, Navigation } from 'swiper/modules';

import Carousel1 from "../../assets/NEW/Carousel1.png"
import Carousel2 from "../../assets/NEW/Carousel2.png"
import Carousel3 from "../../assets/NEW/Carousel3.png"
import Carousel4 from "../../assets/NEW/Carousel4.png"
import LA from "../../assets/NEW/left-arrow.png"
import RA from "../../assets/NEW/right-arrow.png"



export function AuthSlider() {

    return <section className='thisCarousel'>
        <div id="carouselExampleIndicators" className="carousel slide">
            <div className="carousel-inner">
                <div className="carousel-item active">
                    <img src={Carousel1} className="d-block w-100" alt="Carousel img #1" />
                </div>
                <div className="carousel-item">
                    <img src={Carousel2} className="d-block w-100" alt="Carousel img #2" />
                </div>
                <div className="carousel-item">
                    <img src={Carousel3} className="d-block w-100" alt="Carousel img #3" />
                </div>
                <div className="carousel-item">
                    <img src={Carousel4} className="d-block w-100" alt="Carousel img #4" />
                </div>
            </div>
        </div>
        <div className="slider-btns">
            <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
                <img src={LA} alt="left-arrow" />
            </button>
            <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
                <img src={RA} alt="right-arrow" />
            </button>
        </div>
    </section>
}