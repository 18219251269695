import moment  from 'moment';
import { Player } from 'video-react';
import Vimeo from '@u-wave/react-vimeo'
import YouTube from '@u-wave/react-youtube'
import 'video-react/dist/video-react.css';

import VIDEO from "../../images/Wealth-Coach-Waqar-Ad-2.mp4"


const API_URL = process.env.REACT_APP_API_URL;



export function Post(props) {

    console.log("Post", props);

    return <>
        <div className="tweet" style={{ margin: `${props.PostType == "text" && "0px"}`}}>
            <div className="head">
                <div className="profile">
                    {
                        props.profile != "" && <img src={`${API_URL}/static/${props.profile}`} alt="Profile Picture" />
                    }
                </div>
                <div className="user-info">
                    <p>{props.username}</p>
                    <span>{moment(props.timepass).fromNow()}</span>
                </div>
            </div>
            <div className="tweet-content">
                <p>{props.tweet}</p>
            </div>
            <div className="card text-center">
                {
                    props.PostType == "img" ? <img src={`${API_URL}/static/${props.Post}`} alt="post" />
                        :
                        props.PostType == "video" ?
                            <Player
                                playsInline
                                poster={`${API_URL}/static/${props.Poster}`}
                                src={`${API_URL}/static/${props.Post}`}
                            />
                            :
                            props.PostType == "vimeo" ?
                                <Vimeo
                                    video={props.Post}
                                    autoplay={false}
                                    controls={true}
                                />
                                :
                                props.PostType == "youtube" &&
                                <YouTube video={props.Post} />
                }
            </div>
            <div className="foot">
                <div className="left">
                    <p className="m-0">{moment(props.time).format('LT')}</p>
                    {/* <p className="m-0">{moment(props.date).format('ll')}</p> */}
                </div>
                <div className="right">
                    <p className="m-0">{moment(props.date).format('ll')}</p>
                </div>
            </div>
        </div>
    </>
}