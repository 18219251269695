import Table from 'react-bootstrap/Table';
import { getAllSubscription } from '../../helpers/apis';
import { useEffect, useState } from 'react';
import { T2D } from "../../helpers/time2date";
import { SlOptionsVertical } from "react-icons/sl";
import moment from 'moment';
import { Paginate } from '../pagination';


const API_URL = process.env.REACT_APP_API_URL;



function Subscriptions() {
    const urlParams = new URLSearchParams(window.location.search);
    const thisPage = urlParams.get('page');

    const [totalItems, setTotalItems] = useState(0)
    const [perPage, setPerPage] = useState(0)

    const [data, setData] = useState([{
        _id: "",
        username: "",
        email: "",
        status: "",
        role: "",
        timestamp: "",
    }])

    const AllSubs = async (query) => {
        const res = await getAllSubscription(query)
        if (res.success) {
            console.log("res.message")
            console.log(res.message)
            setData(res.message)
            setTotalItems(res.totalPosts)
            setPerPage(res.perPage)
        }
    }

    const isSubsActive_Style = (subs) => {
        if (subs != null && typeof subs != "object" && (subs)?.toUpperCase() == "ACTIVE") return { color: "var(--textSecondColor)", fontWeight: 600, textTransform: "uppercase" }
        else return { color: "var(--bs-danger)", fontWeight: 600, textTransform: "uppercase" }
    }

    function getExpiryDate(date, plantTpe) {
        // Ensure Moment.js is available
        if (!moment) throw new Error('Moment.js library is required. Please include it in your project.');
        if (!date) return console.log('date not found.');
        console.log("date", date);
        // Create a Moment object from the passed date
        const dateMoment = moment(date);

        // Add 1 month to the date
        dateMoment.add(1, plantTpe == "yearly" ? "years" : "months");

        // Set the date to the same day of the month (if it exists in the next month)
        dateMoment.date(date.getDate());

        // Return the formatted date string
        return dateMoment.format('YYYY-MM-DD'); // Or any desired format
    }

    useEffect(() => {

        localStorage.setItem("lastPage", window.location.hash)
        AllSubs(localStorage.getItem("lastPage") == window.location.hash ? `page=${thisPage}` : `page=${0}`)

    }, [thisPage])


    return (
        <>
            <h4>Total Subscribers: {totalItems}</h4>

            <section className='signal-table'>
                <Table hover responsive style={{ minHeight: "300px" }}>
                    <thead>
                        <tr>
                            <th>Username</th>
                            <th>Email</th>
                            <th>Subscription</th>
                            <th>Payment Date</th>
                            <th>Expiry Date</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            data?.map((item, i) => {
                                console.log(item);
                                return <tr key={item._id}>
                                    <td> {item.username}</td>
                                    <td> {item.email}</td>
                                    <td style={isSubsActive_Style(item.subscription)}> {item.subscription}</td>
                                    <td> {
                                        (item.subscription)?.toUpperCase() == "ACTIVE" ?
                                            moment(new Date(item?.subs_data?.startDate)).format("YYYY-MM-DD")
                                            :
                                            "---"
                                    }
                                    </td>
                                    <td>
                                        {
                                            (item.subscription)?.toUpperCase() == "ACTIVE" ?
                                                getExpiryDate(new Date(item?.subs_data?.startDate), item?.subs_data?.planType)
                                                :
                                                "---"
                                        }
                                    </td>
                                </tr>
                            })
                        }
                    </tbody>
                </Table>
            </section>

            <Paginate totalItems={totalItems} itemsPerPage={perPage} redirectTo={"#paid-users"} />

        </>
    );
}


export default Subscriptions;