import { useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { forgetPassword } from "../../helpers/apis";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { AuthSlider } from "./slider";
import Logo from "../../assets/NEW/logo.png"
import { useMediaQuery } from 'react-responsive'
import { Form, FloatingLabel, Row, Col, Button, Modal } from 'react-bootstrap';


export function Forget() {
    const navigate = useNavigate()
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 992px)' })

    const [email, setEmail] = useState("")


    async function Submit(e) {
        e.preventDefault()

        forgetPassword({ email })
            .then(data => {
                if (!data.success) return notify(data.message, toast.error)
                notify(data.message, toast.success)
                setEmail("")
            })
            .catch(err => {
                console.log(err.response.data);
                return notify(err.response.data, toast.error)
            })
    }


    const notify = (response, types) =>
        types(response, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });



    return <>
        <ToastContainer style={{ width: "max-content" }} />

        <nav className="navbar navbar-expand-lg homeNav py-3" id="navbar">
            <div className="container">
                <a className="navbar-brand" href="/" >
                    <img className='img-fluid' src={Logo} alt="logo" />
                </a>
            </div>
        </nav>

        <div className="qw123">
            <div className={`${isTabletOrMobile ? "container-fluid" : "container"}  `}>
                <div className="row">
                    <div className="col-12 col-lg-6">
                        <Form className="login" onSubmit={(e) => Submit(e)}>
                            <div className="mb-3 text-center row">
                                <h1 style={{ color: "var(--textPrimaryColor)" }}>Forget Your Password</h1>
                            </div>

                            <FloatingLabel controlId="email" label="Enter your Email" className="mb-3 row">
                                <Form.Control
                                    type="email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    placeholder="Enter your Email"
                                    required
                                />
                            </FloatingLabel>

                            <div className="mb-3 row text-end">
                            </div>
                            <div className="mb-3">
                                <div className="d-flex justify-content-center">
                                    <button className="btn green_button">Send Reset Link</button>
                                </div>
                                <div className="my-3 wq24">
                                    Want to Sign in?
                                    <a href={"/auth"} style={{ color: "var(--textPrimaryColor)" }}>Signin</a>
                                </div>
                            </div>
                        </Form>
                    </div>
                    <div className={`${isTabletOrMobile && "d-none"} col col-lg-6 `}>
                        <AuthSlider />
                    </div>
                </div>
            </div>
        </div>
    </>
}