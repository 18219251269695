import * as CryptoJS from 'crypto-js';


export function IsLogin(hash) {
    if(hash != "" && hash && typeof hash != "undefined") {
        console.log("CryptoJS", CryptoJS.AES);
        var bytes = CryptoJS.AES.decrypt(hash, process.env.REACT_APP_Secret);
        var originalText = bytes.toString(CryptoJS.enc.Utf8);
        
        console.log("Decrypt: ", originalText);
        return originalText
    }
}
