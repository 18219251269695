import { useEffect, useState } from 'react';
import { editPost } from '../../helpers/apis';
import { IsLogin } from '../../helpers/isLogin';
import { FaEdit } from 'react-icons/fa';
import { Form, Row, Col, FloatingLabel, Button, Modal } from 'react-bootstrap';



function EditPosts(props) {
    const [show, setShow] = useState(false);
    const [user, setUser] = useState({
        id: ""
    })

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [data, setData] = useState({
        userID: "",
        PostType: "",
        poster: "",
        post: "",
        tweet: "",
        status: ""
    })

    async function submit() {
        const myForm = new FormData();
        myForm.set("_id", props.data._id);
        myForm.set("userID", data.userID);
        myForm.set("PostType", data.PostType);
        myForm.set("poster", data.poster);
        myForm.set("post", data.post);
        myForm.set("tweet", data.tweet);
        myForm.set("status", data.status);

        const res = await editPost(myForm);
        alert(res.message)
        handleClose()
    }

    useEffect(() => {
        setData({
            ...props.data,
            userID: (props.data).userID._id,
            poster: (props.data).Poster,
            post: (props.data).Post,
            PostType: (props.data).PostType,
        })

    }, [])


    return (
        <>
            <button className='btn' onClick={handleShow}> <FaEdit /> </button>

            <Modal show={show} onHide={handleClose} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>Update Posts</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <Form enctype='multipart/form-data'>
                        <Row className='my-4'>
                            <Col className='visually-hidden'>
                                <FloatingLabel controlId="formGroupEmail" label="User ID">
                                    <Form.Control
                                        className='p-3'
                                        value={data.userID}
                                        onChange={(e) => setData({ ...data, "userID": e.target.value })}
                                        placeholder="User ID"
                                    />
                                </FloatingLabel>
                            </Col>
                            <Col>
                                <FloatingLabel controlId="formGroupPostType" label="Post Type">
                                    <Form.Select
                                        className='p-3'
                                        value={data.PostType}
                                        onChange={(e) => setData({ ...data, "PostType": e.target.value, poster: "", post: "" })}
                                    >
                                        <option value={""} disabled>Choose Post Type</option>
                                        <option value="text">Text Only</option>
                                        <option value="img">Image with Text</option>
                                        <option value="video">Video with Text</option>
                                        <option value="vimeo">Vimeo with Text</option>
                                        <option value="youtube">Youtube with Text</option>
                                    </Form.Select>
                                </FloatingLabel>
                            </Col>
                        </Row>
                        <Row className='my-4'>
                            {
                                data.PostType !== "text" &&
                                <>
                                    {
                                        (data.PostType === "video") &&
                                        <Col>
                                            <FloatingLabel controlId="formGroupPoster" label="Poster">
                                                <Form.Control
                                                    className='p-3'
                                                    type='file'
                                                    accept="image/*"
                                                    onChange={(e) => setData({ ...data, "poster": e.target.files[0] })}
                                                    placeholder="Poster"
                                                />
                                            </FloatingLabel>
                                        </Col>
                                    }
                                    <Col>
                                        {
                                            (data.PostType === "img") ?
                                                <FloatingLabel controlId="formGroupImage" label="Image">
                                                    <Form.Control
                                                        className='p-3'
                                                        type='file'
                                                        accept="image/*"
                                                        onChange={(e) => setData({ ...data, "post": e.target.files[0] })}
                                                        placeholder="Post"
                                                    />
                                                </FloatingLabel>
                                                :
                                                (data.PostType === "video") ?
                                                    <FloatingLabel controlId="formGroupVideo" label="Video">
                                                        <Form.Control
                                                            className='p-3'
                                                            type='file'
                                                            accept="video/*"
                                                            onChange={(e) => setData({ ...data, "post": e.target.files[0] })}
                                                            placeholder="Post"
                                                        />
                                                    </FloatingLabel>
                                                    :
                                                    (data.PostType === "vimeo") ?
                                                        <FloatingLabel controlId="formGroupVimeo" label="Vimeo">
                                                            <Form.Control
                                                                className='p-3'
                                                                type='text'
                                                                value={data.post}
                                                                onChange={(e) => setData({ ...data, "post": e.target.value })}
                                                                placeholder="Video link"
                                                            />
                                                        </FloatingLabel>
                                                        :
                                                        (data.PostType === "youtube") ?
                                                            <FloatingLabel controlId="formGroupYoutube" label="Youtube">
                                                                <Form.Control
                                                                    className='p-3'
                                                                    type='text'
                                                                    value={data.post}
                                                                    onChange={(e) => setData({ ...data, "post": e.target.value })}
                                                                    placeholder="Video link"
                                                                />
                                                            </FloatingLabel>
                                                            :
                                                            ""
                                        }
                                    </Col>
                                </>
                            }
                        </Row>
                        <Row className='my-4'>
                            <Col>
                                <FloatingLabel controlId="formGroupText" label="Text">
                                    <Form.Control
                                        className='p-3'
                                        value={data.tweet}
                                        onChange={(e) => setData({ ...data, "tweet": e.target.value })}
                                        placeholder="Text"
                                    />
                                </FloatingLabel>
                            </Col>
                            <Col>
                                <FloatingLabel controlId="formGroupStatus" label="Post Availability">
                                    <Form.Select
                                        className='p-3'
                                        value={data.status}
                                        onChange={(e) => setData({ ...data, "status": e.target.value })}
                                    >
                                        <option value="show">Make it available</option>
                                        <option value="hide">Save in draft</option>
                                    </Form.Select>
                                </FloatingLabel>
                            </Col>
                        </Row>
                    </Form>

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}> Cancel </Button>
                    <Button variant="dark" onClick={() => submit()}> Update Now </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default EditPosts;