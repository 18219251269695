import { useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { resetPassword } from "../../helpers/apis";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { AuthSlider } from "./slider";
import Logo from "../../assets/NEW/logo.png"
import { useMediaQuery } from 'react-responsive'
import { Form, FloatingLabel, Row, Col, Button, Modal } from 'react-bootstrap';


export function Reset() {
    const navigate = useNavigate()
    const { token } = useParams()
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 992px)' })

    const [otp, setOTP] = useState()
    const [password, setPassword] = useState("")
    const [confirmPass, setConfirmPass] = useState("")
    const [PassShow, setPassShow] = useState(false)
    const [PassShow2, setPassShow2] = useState(false)


    async function Submit(e) {
        e.preventDefault()

        if (password != confirmPass) return notify("Passwords not match", toast.error)

        resetPassword({ otp, password, token })
            .then(data => {
                if (!data.success) return notify(data.message, toast.error)
                notify(data.message, toast.success)

                setTimeout(() => {
                    return navigate("/auth")
                }, 2000);
            })
            .catch(err => {
                console.log(err.response.data);
                return notify(err.response.data, toast.error)
            })
    }


    const notify = (response, types) =>
        types(response, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });



    return <>
        <nav className="navbar navbar-expand-lg homeNav py-3" id="navbar">
            <div className="container">
                <a className="navbar-brand" href="/" >
                    <img className='img-fluid' src={Logo} alt="logo" />
                </a>
            </div>
        </nav>

        <div className="qw123">
            <ToastContainer style={{ width: "max-content" }} />


            <div className={`${isTabletOrMobile ? "container-fluid" : "container"}  `}>
                <div className="row">
                    <div className="col-12 col-lg-6">
                        <Form className="login" onSubmit={(e) => Submit(e)}>
                            <div className="mb-3 text-center row">
                                <h1 style={{ color: "var(--textPrimaryColor)" }}>Reset Your Password</h1>
                            </div>

                            <FloatingLabel controlId="otp" label="Enter your OTP" className="mb-3 row">
                                <Form.Control
                                    type="text"
                                    value={otp}
                                    onChange={(e) => setOTP(e.target.value)}
                                    placeholder="Enter your OTP"
                                    required
                                />
                            </FloatingLabel>

                            <FloatingLabel controlId="password" label="Enter your Password" className="mb-3 row">
                                <Form.Control
                                    type={!PassShow ? "password" : "text"}
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    placeholder="Enter your Password"
                                    required
                                />
                                <span className="icon-eye" data-eye-icon={!PassShow ? "\uf06e" : "\uf070"} onClick={() => setPassShow(!PassShow)}></span>
                            </FloatingLabel>

                            <FloatingLabel controlId="confirmPass" label="Enter your confirm Password" className="mb-3 row">
                                <Form.Control
                                    type={!PassShow2 ? "password" : "text"}
                                    value={confirmPass}
                                    onChange={(e) => setConfirmPass(e.target.value)}
                                    placeholder="Enter your confirm Password"
                                    required
                                />
                                <span className="icon-eye" data-eye-icon={!PassShow2 ? "\uf06e" : "\uf070"} onClick={() => setPassShow2(!PassShow2)}></span>
                            </FloatingLabel>

                            <div className="mb-3 row text-end">
                            </div>
                            <div className="mb-3">
                                <div className="d-flex justify-content-center">
                                    <button className="btn green_button">Reset Now</button>
                                </div>
                                <div className="my-3 wq24">
                                    Want to Sign in?
                                    <a href={"/auth"} style={{ color: "var(--textPrimaryColor)" }}>Signin</a>
                                </div>
                            </div>
                        </Form>
                    </div>
                    <div className={`${isTabletOrMobile && "d-none"} col col-lg-6 `}>
                        <AuthSlider />
                    </div>
                </div>
            </div>

        </div>
    </>

}