import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { google_signin_user, signin_user } from "../../helpers/apis";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { AuthSlider } from "./slider";
import Logo from "../../assets/NEW/logo.png"
import Apple from "../../assets/NEW/apple.png"
import Google from "../../assets/NEW/google.png"
import { useMediaQuery } from 'react-responsive'
import { useAuth } from '../../ContextAPI/Components/auth'
import { Navbar } from "../navbar"
import { Form, FloatingLabel, Row, Col, Button, Modal } from 'react-bootstrap';


import { useGoogleLogin } from '@react-oauth/google';


export function LoginForm() {
    const { user, setUser, subs } = useAuth()
    const navigate = useNavigate()
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 992px)' })
    const [PassShow, setPassShow] = useState(false)

    const [data, setData] = useState({
        email: "",
        password: "",
    })


    async function Submit(e) {
        e.preventDefault()

        signin_user(data)
            .then(__data => {
                if (!__data.success) {
                    if (__data?.reason == "blocked") navigate(`/verify-account/${data.email}`)
                    return notify(__data.message, toast.error)
                }
                window.localStorage.setItem("token", __data.token)
                setUser(__data.user)
                subs(__data.user.id)
                notify(__data.message, toast.success)
                setTimeout(() => {
                    return window.location.href = ("/positions")
                }, 2000);
            })
            .catch(err => {
                console.log(err.response.data);
                if (!err.response.data) return notify(data.message, toast.error)
            })
    }


    const notify = (response, types) =>
        types(response, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });


    const login = useGoogleLogin({ onSuccess: handleGoogleLoginSuccess });

    function handleGoogleLoginSuccess(tokenResponse) {

        const accessToken = tokenResponse.access_token;

        console.log("tokenResponse", tokenResponse);
        console.log("accessToken", accessToken);

        google_signin_user({ token: accessToken })
            .then(__data => {
                if (!__data.success) {
                    if (__data?.reason == "blocked") navigate(`/verify-account/${data.email}`)
                    return notify(__data.message, toast.error)
                }
                window.localStorage.setItem("token", __data.token)
                setUser(__data.user)
                subs(__data.user.id)
                notify(__data.message, toast.success)
                setTimeout(() => {
                    return window.location.href = ("/positions")
                }, 2000);
            })
            .catch(err => {
                console.log(err.response.data);
                if (!err.response.data) return notify(data.message, toast.error)
            })
    }

    return <>
        <ToastContainer style={{ width: "max-content" }} />



        <div className={`${isTabletOrMobile ? "container-fluid" : "container"}  `}>
            <div className="row">
                <div className="col-12 col-lg-6">
                    <Form className="login" onSubmit={(e) => Submit(e)}>
                        <div className="mb-3 text-center row">
                            <h1 style={{ color: "var(--textPrimaryColor)" }}>Welcome To, <br /> Capital Consultant</h1>
                        </div>

                        <FloatingLabel controlId="email" label="Email" className="mb-3 row">
                            <Form.Control
                                type="email"
                                value={data.email}
                                onChange={(e) => setData({ ...data, email: e.target.value })}
                                placeholder="Enter Email"
                                required
                            />
                        </FloatingLabel>

                        <FloatingLabel controlId="password" label="Password" className="mb-3 row">
                            <Form.Control
                                                     type={!PassShow ? "password" : "text"}
                                                     value={data.password}
                                onChange={(e) => setData({ ...data, password: e.target.value })}
                                placeholder="Enter Password"
                                required
                            />
                            <span className="icon-eye" data-eye-icon={!PassShow ? "\uf06e" : "\uf070"} onClick={() => setPassShow(!PassShow)}></span>
                        </FloatingLabel>

                        <div className="mb-3 row text-end">
                            <a href="/forget-password" style={{ textDecoration: "none", color: "var(--textPrimaryColor)" }}>Forget password?</a>
                        </div>

                        <div className="mb-3">
                            <div className="d-flex justify-content-center">
                                <button className="btn green_button">Sign In</button>
                            </div>

                            <div className="my-3 wq24">
                                Don't have an account?
                                <a href={"#signup"} style={{ color: "var(--textPrimaryColor)" }}>Signup</a>
                            </div>
                        </div>

                        <div className="mb-3 row mx-auto w-100">
                            <div className="col-sm-12 social">
                                <Button variant="" onClick={() => login()}>
                                    <img src={Google} alt="Google" className="me-1" />
                                    Sign up with Google
                                </Button>
                            </div>
                        </div>
                    </Form>
                </div>
                <div className={`${isTabletOrMobile && "d-none"} col col-lg-6 `}>
                    <AuthSlider />
                </div>
            </div>
        </div>
    </>
}