import React, { useEffect, useState } from 'react'


export default function TradingViewWidget() {
    let active = (false);

    useEffect(() => {

        if(!active) {

            const script = document.createElement('script');
            script.src = 'https://s3.tradingview.com/external-embedding/embed-widget-timeline.js';
            script.async = true;
            script.innerHTML = `
                {
                "feedMode": "all_symbols",
                "isTransparent": false,
                "displayMode": "regular",
                "width": "100%",
                "height": "450",
                "colorTheme": "light",
                "locale": "en"
                }
            `
            let topnews = document.getElementById('topnews');
            topnews.appendChild(script);

        }

        active=(true)

    }, [])



    return <div id='topnews'></div>;
}
