import Table from 'react-bootstrap/Table';
import Dropdown from 'react-bootstrap/Dropdown';
import { getMonthlyReport } from '../../helpers/apis';
import { useEffect, useState } from 'react';
import { T2D } from "../../helpers/time2date";
import { SlOptionsVertical } from "react-icons/sl";
import { CSVLink } from "react-csv";
import { IoMdDownload } from "react-icons/io";


const API_URL = process.env.REACT_APP_API_URL;



function MonthlyReport() {
    const [data, setData] = useState([{
        creator: "",
        signalsPosted: "",
        totalProfit: "",
        totalLoss: "",
    }])

    const monthlyReport = async () => {
        const res = await getMonthlyReport()
        if (res.success) {
            setData(res.message)
        }
    }


    useEffect(() => {
        monthlyReport()

    }, [])



    return (
        <>
            <div style={{ display: "flex", justifyContent: "end" }}>
                <div style={{ width: "200px", marginBottom: "15px" }}>
                    <CSVLink
                        filename={"Monthly-Report.csv"}
                        className="btn green_button mx-2"
                        style={{ height: "38px", padding: "5px 10px", borderRadius: "5px", fontWeight: 500 }}
                        data={data.map((item) => {
                            return {
                                "CREATOR": item.creator || "NA",
                                "SIGNALS POSTED": item.signalsPosted,
                                "PROFITABLE": item.totalProfit,
                                "LOSS-MAKING": item.totalLoss,
                            }
                        })}>
                        Download Report <IoMdDownload />
                    </CSVLink>
                </div>
            </div>


            <section className='signal-table'>
                <Table hover responsive style={{ minHeight: "300px" }}>
                    <thead>
                        <tr>
                            <th>Creator</th>
                            <th>Signals Posted</th>
                            <th>Total Profitable Signals</th>
                            <th>Total Loss Signal</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            data.map((item, i) => {
                                return <tr key={item._id}>
                                    <td> {item.creator}</td>
                                    <td> {item.signalsPosted}</td>
                                    <td> {item.totalProfit}</td>
                                    <td> {item.totalLoss}</td>
                                </tr>
                            })
                        }
                    </tbody>
                </Table>


            </section>
        </>
    );
}


export default MonthlyReport;