import { useEffect, useState } from 'react';
import { Form, FloatingLabel, Row, Col, Button, Modal } from 'react-bootstrap';
import { editSignal, getSignal } from '../../helpers/apis';
import { BiSolidEdit } from "react-icons/bi"



function EditSignal({ id, allSignals }) {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [data, setData] = useState([])

    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        maximumFractionDigits: 2, // (causes 2500.99 to be printed as $2,501)
    });

    const GetSignal = async () => {
        const res = await getSignal({ id });
        setData(res.message)
    }

    async function submit() {
        const res = await editSignal(data[0]);
        if (res.success) {
            alert(res.message)
            allSignals()
        }
    }

    useEffect(() => {
        if (show) GetSignal()

    }, [show])



    return (
        <>
            <button className='btn ' onClick={handleShow}><BiSolidEdit /></button>

            <Modal show={show} onHide={handleClose} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>Edit Signal</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    {
                        data.length > 0 &&
                        <Form>
                            <Row className='my-4'>
                                <Col>
                                    <FloatingLabel controlId="crypto" label="Crypto">
                                        <Form.Control
                                            className='p-3'
                                            value={data[0].Crypto}
                                            onChange={(e) => setData([{ ...data[0], "Crypto": e.target.value }])}
                                            placeholder="Crypto"
                                        />
                                    </FloatingLabel>
                                </Col>
                                <Col>
                                    <FloatingLabel controlId="longShort" label="TRADE TYPE">
                                        <Form.Select
                                            className='p-3'
                                            value={data[0].Long_Short}
                                            onChange={(e) => setData([{ ...data[0], "Long_Short": e.target.value }])}
                                        >
                                            <option value="long">Long</option>
                                            <option value="short">Short</option>
                                            <option value="spot">Spot</option>
                                            <option value="swing">Swing</option>
                                        </Form.Select>
                                    </FloatingLabel>
                                </Col>
                            </Row>
                            <Row className='my-4'>
                                <Col>
                                    <FloatingLabel controlId="entryDate" label="Entry Date">
                                        <Form.Control
                                            className='p-3'
                                            type='date'
                                            value={data[0].Entry_Date}
                                            onChange={(e) => setData([{ ...data[0], "Entry_Date": e.target.value }])}
                                            placeholder="Entry Date"
                                        />
                                    </FloatingLabel>
                                </Col>
                                <Col>
                                    <FloatingLabel controlId="averagePrice" label="Entry Price">
                                        <Form.Control
                                            className='p-3'
                                            value={data[0].Average_Price}
                                            onChange={(e) => setData([{ ...data[0], "Average_Price": e.target.value }])}
                                            placeholder="Entry Price"
                                        />
                                    </FloatingLabel>
                                </Col>
                            </Row>
                            <Row className='my-4'>
                                <Col>
                                    <FloatingLabel controlId="stop" label="Stop">
                                        <Form.Control
                                            className='p-3'
                                            value={data[0].Stop}
                                            onChange={(e) => setData([{ ...data[0], "Stop": e.target.value }])}
                                            placeholder="Stop"
                                        />
                                    </FloatingLabel>
                                </Col>
                                <Col>
                                    <FloatingLabel controlId="target" label="Target">
                                        <Form.Control
                                            className='p-3'
                                            value={data[0].Target}
                                            onChange={(e) => setData([{ ...data[0], "Target": e.target.value }])}
                                            placeholder="Target"
                                        />
                                    </FloatingLabel>
                                </Col>
                            </Row>
                            <Row className='my-4'>
                                <Col>
                                    <FloatingLabel controlId="recommendedProfit" label="Portfolio % to be applied">
                                        <Form.Control
                                            className='p-3'
                                            type='number'
                                            value={data[0].Recommended_Portfolio}
                                            onChange={(e) => setData([{ ...data[0], "Recommended_Portfolio": e.target.value }])}
                                            placeholder="Portfolio % to be applied"
                                        />
                                    </FloatingLabel>
                                </Col>
                                <Col>
                                    <FloatingLabel controlId="status" label="Status">
                                        <Form.Select
                                            className='p-3'
                                            value={data[0].status}
                                            onChange={(e) => setData([{ ...data[0], "status": e.target.value }])}
                                        >
                                            <option value={"ongoing"}>Ongoing</option>
                                            <option value={"ended"}>End</option>
                                        </Form.Select>
                                    </FloatingLabel>
                                </Col>
                            </Row>
                            <Row className='my-4'>
                                <Col>
                                    <FloatingLabel controlId="price" label="Exit Price">
                                        <Form.Control
                                            className='p-3'
                                            value={data[0].Price}
                                            type='number'
                                            onChange={(e) => setData([{ ...data[0], "Price": e.target.value }])}
                                            placeholder="Price"
                                        />
                                    </FloatingLabel>
                                </Col>
                                <Col>
                                    <FloatingLabel controlId="exitDate" label="Exit Date">
                                        <Form.Control
                                            className='p-3'
                                            type='date'
                                            value={data[0].Exit_Date}
                                            onChange={(e) => setData([{ ...data[0], "Exit_Date": e.target.value }])}
                                            placeholder="Exit_Date"
                                        />
                                    </FloatingLabel>
                                </Col>
                            </Row>
                            <Row className='my-4'>
                                <Col>
                                    <FloatingLabel controlId="bookedProfit" label="Booked Profit in Percentage">
                                        <Form.Control
                                            className='p-3'
                                            type='number'
                                            value={data[0].Profit}
                                            onChange={(e) => setData([{ ...data[0], "Profit": e.target.value }])}
                                            placeholder="Profit"
                                        />
                                    </FloatingLabel>
                                </Col>
                                <Col></Col>
                            </Row>
                        </Form>
                    }

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}> Close </Button>
                    <Button variant="dark" onClick={() => submit()}> Edit </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default EditSignal;