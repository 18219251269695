import moment from "moment";


export function isUserSubscriptionActive(user) {

    if(user?.subscription != null && typeof user?.subscription != "object" && (user?.subscription)?.toUpperCase() == "ACTIVE") {

        if (typeof user?.subs_data == "object" && isExpired(new Date(user?.subs_data?.startDate), user?.subs_data?.planType)) {
            return true;

        } else {
            return false
            
        }

    } else {
        return false
    }
}


function isExpired(date, plantTpe) {
    // Ensure Moment.js is available
    if (!moment) throw new Error('Moment.js library is required. Please include it in your project.');
    if (!date) return console.log('date not found.');

    // Create a Moment object from the passed date
    const dateMoment = moment(date);

    // Add 1 month to the date
    dateMoment.add(1, plantTpe == "yearly" ? "years" : "months");

    // Set the date to the same day of the month (if it exists in the next month)
    dateMoment.date(date.getDate());

    return dateMoment.valueOf() > Date.now()
}