import Table from 'react-bootstrap/Table';
import { getAllPayments } from '../../helpers/apis';
import { useEffect, useState } from 'react';
import { T2D } from "../../helpers/time2date";
import { SlOptionsVertical } from "react-icons/sl";
import moment from 'moment';
import { Paginate } from '../pagination';



function UserPayments({ userId }) {
    const [data, setData] = useState([{
        pay_address: "",
        pay_amount: "",
        pay_currency: "",
        payment_status: ""
    }])

    const AllSubs = async () => {
        const res = await getAllPayments(userId)
        if (res) {
            console.log(res)
            setData(res)
        }
    }

    useEffect(() => {
        // get user data
        AllSubs();
    }, [])


    return (
        <>

            <section className='signal-table'>
                <Table hover responsive>
                    <thead>
                        <tr>
                            <th>Address</th>
                            <th>Amount</th>
                            <th>Currency</th>
                            <th>Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            data?.map((item, i) => {
                                console.log(item);
                                return <tr key={`payment${i}`}>
                                    <td> {item.pay_address}</td>
                                    <td> {item.pay_amount}</td>
                                    <td style={{ textTransform: "uppercase" }}> {item.pay_currency}</td>
                                    <td style={{
                                        fontWeight: 600,
                                        color: item.payment_status == "expired" ? "var(--bs-danger)" : item.payment_status == "partially_paid" ? "gray" : "var(--textSecondColor)"
                                    }}> {item.payment_status}</td>
                                </tr>
                            })
                        }
                    </tbody>
                </Table>
            </section>
        </>
    );
}

export default UserPayments;