import React, { useEffect, useState } from 'react'
import ReactPaginate from "react-paginate";
import {useNavigate} from "react-router-dom"


export const Paginate = ({itemsPerPage=10, totalItems=0, redirectTo}) => {
    const navigate = useNavigate();
    const items = [...Array(totalItems).keys()];

    const [pageCount, setPageCount] = useState(0);
    const [itemOffset, setItemOffset] = useState(0);

    // Invoke when user click to request another page.
    const handlePageClick = (event) => {
        console.log(event.selected);
        // myCurrentPageState(event.selected + 1)
      
        const newOffset = event.selected * itemsPerPage % items.length;
        console.log(`User requested page number ${event.selected}, which is offset ${newOffset}`);

        navigate(`?page=${event.selected+1}${redirectTo}`)

    };

    useEffect(() => {
        // Fetch items from another resources.
        const endOffset = itemOffset + itemsPerPage;
        console.log(`Loading items from ${itemOffset} to ${endOffset}`);
        // setCurrentItems(items.slice(itemOffset, endOffset));
        setPageCount(Math.ceil(items.length / itemsPerPage));
        
    }, [itemOffset, itemsPerPage]);



    return (
        <>
            <div className="px-2 py-3 d-flex align-items-center justify-content-end">

                <ReactPaginate
                    initialPage={0}
                    nextLabel=">"
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={3}
                    marginPagesDisplayed={2}
                    pageCount={pageCount}
                    previousLabel="<"
                    pageClassName="page-item"
                    pageLinkClassName="page-link"
                    previousClassName="page-item"
                    previousLinkClassName="page-link"
                    nextClassName="page-item"
                    nextLinkClassName="page-link"
                    breakLabel="..."
                    breakClassName="page-item"
                    breakLinkClassName="page-link"
                    containerClassName="pagination"
                    activeClassName="active"
                    renderOnZeroPageCount={null}
                />

            </div>
        </>
    )
}
