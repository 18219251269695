import { useEffect, useMemo, useState } from "react";
import eth from "../../assets/NEW/eth.png"
import btc from "../../assets/NEW/btc.png"
import { globalMetrics } from "../../helpers/apis";



export function CurrencyWidget({ socket }) {
    const [cards22, setCards22] = useState([]);
    const [EPrice, setEPrice] = useState(0);
    const [BPrice, setBPrice] = useState(0);
    const [loading, setLoading] = useState(false);


    const getRates = async () => {
        try {
            setLoading(true)
            const res = await globalMetrics();
            if (res.success) {
                console.log(res.message);
                setCards22(res.message.data)
                setLoading(false)
            } else {
                console.log(res.message);
                setCards22([])
                setLoading(false)
            }

        } catch (error) {
            console.log(error);
            setLoading(false)
        }
    }

    function getNumber(num) {
        var units = ["M", "B", "T", "Q"]
        var unit = Math.floor((num / 1.0e+1).toFixed(0).toString().length)
        var r = unit % 3
        var x = Math.abs(Number(num)) / Number('1.0e+' + (unit - r)).toFixed(2)
        return x.toFixed(2) + '' + units[Math.floor(unit / 3) - 2]
    }

    useMemo(() => {
        const MINUTE_MS = 120000;
        const interval = setInterval(() => {
            setCards22([]);
            setEPrice(0);
            setBPrice(0);

            getRates()
            console.log('Logs every minute');

        }, MINUTE_MS);

        return () => clearInterval(interval);

    }, [])


    useEffect(() => {
        getRates()

    }, [])


    useEffect(() => {
        socket.on('tradeData', (marketData) => {
            // console.log(marketData);
            
            if(marketData?.stream == "ethusdt@aggTrade")setEPrice(marketData?.data?.p)
            if(marketData?.stream == "btcusdt@markPrice") setBPrice(marketData?.data?.p)

        });

        socket.on('connect', () => {
            // Retrieve the socket ID
            window.localStorage.setItem("st", socket.id); // socket ID saving in localstorage
        });


        return () => {
            socket.off('tradeData');
        };
    }, []);


    return <>
        <div className="vi-banner">
            {
                (loading) ?
                    <div className="w-100 text-center">
                        <div className="spinner-border text-light" style={{ fontSize: "30px", width: "4rem", height: "4rem" }} role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                    </div>
                    :
                    <div className="container h-100">
                        <div className="row align-items-center">
                            {
                                cards22?.quote?.USD?.total_market_cap &&
                                <div className="col-md-3 col-sm-6 my-2">
                                    <div className="card">
                                        <div className="topbar success"></div>
                                        <div className="a13">Total Market Cap</div>
                                        <div className="a14">
                                            <div>${getNumber(cards22?.quote?.USD?.total_market_cap)}</div>
                                            <div className="right ">
                                                {/* +0.22% <span>24hrs</span> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                            {
                                cards22?.quote?.USD?.total_volume_24h &&
                                <div className="col-md-3 col-sm-6 my-2">
                                    <div className="card">
                                        <div className="topbar success"></div>
                                        <div className="a13">Total Volume 24h</div>
                                        <div className="a14">
                                            <div>${getNumber(cards22?.quote?.USD?.total_volume_24h)}</div>
                                        </div>
                                    </div>
                                </div>
                            }
                            {
                                cards22?.btc_dominance &&
                                <div className="col-md-3 col-sm-6 my-2">
                                    <div className="card">
                                        <div className="topbar success"></div>
                                        <div className="a13">BTC Dominance</div>
                                        <div className="a14">
                                            <div>{(cards22?.btc_dominance).toFixed(2)}%</div>
                                        </div>
                                    </div>
                                </div>
                            }
                            {
                                cards22?.eth_dominance &&
                                <div className="col-md-3 col-sm-6 my-2">
                                    <div className="card">
                                        <div className="topbar success"></div>
                                        <div className="a13">ETH Dominance</div>
                                        <div className="a14">
                                            <div>{(cards22?.eth_dominance).toFixed(2)}%</div>
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                        <div className="row align-items-center">
                            {
                                cards22?.defi_market_cap &&
                                <div className="col-md-3 col-sm-6 my-2">
                                    <div className="card">
                                        <div className="topbar success"></div>
                                        <div className="a13">
                                            Defi Market Cap
                                        </div>
                                        <div className="a14">
                                            <div>${getNumber(cards22?.defi_market_cap)}</div>
                                        </div>
                                    </div>
                                </div>
                            }
                            {
                                cards22?.defi_volume_24h &&
                                <div className="col-md-3 col-sm-6 my-2">
                                    <div className="card">
                                        <div className="topbar success"></div>
                                        <div className="a13">
                                            Defi Volume 24h
                                        </div>
                                        <div className="a14">
                                            <div>${getNumber(cards22?.defi_volume_24h)}</div>
                                        </div>
                                    </div>
                                </div>
                            }
                            <div className="col-md-3 col-sm-6 my-2">
                                <div className="card">
                                    <div className="topbar success"></div>
                                    <div className="a13">
                                        <img className="me-2" src={btc} alt="btc" />
                                        BTC Price
                                    </div>
                                    <div className="a14">
                                        <div>${(parseFloat(BPrice))?.toFixed(2)}</div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3 col-sm-6 my-2">
                                <div className="card">
                                    <div className="topbar success"></div>
                                    <div className="a13">
                                        <img className="me-2" src={eth} alt="eth" />
                                        ETH Price
                                    </div>
                                    <div className="a14">
                                        <div>${(parseFloat(EPrice))?.toFixed(2)}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
            }

        </div>
    </>
}