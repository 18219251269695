import { LoginForm } from "./loginForm";
import { SignupForm } from "./signupForm";
import WQ from "../../assets/NEW/wq.png"
import { useEffect, useState } from "react";
import { useLocation } from 'react-router-dom';
import Logo from "../../assets/NEW/logo.png"



export function Register() {
    const location = useLocation();
    const [hash, setHash] = useState(location.hash)

    useEffect(() => {
        if (location.hash == "#signup") return setHash("#signup")
        if (location.hash == "#signin") return setHash("#signin")
        setHash("#signin")

    }, [location.hash])



    return <>
        <nav className="navbar navbar-expand-lg homeNav py-3" id="navbar">
            <div className="container">
                <a className="navbar-brand" href="/" >
                    <img className='img-fluid' src={Logo} alt="logo" />
                </a>
            </div>
        </nav>

        <div className="qw123">
            {hash == "#signup" && <SignupForm />}
            {hash == "#signin" && <LoginForm />}
        </div>
    </>
}