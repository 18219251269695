import React from 'react'

export const Privacy = () => {
  return (
    <>
    <h5>Privacy Policy for CAPITAL CONSULTANT WEB3.0 Education Platform</h5>
    <p>At CAPITAL CONSULTANT, we are committed to maintaining the privacy of our clients and ensure that their personal information is protected. This privacy policy outlines how we collect, handle, and protect the personal information of our users.</p>

    <h6>Information Collected</h6>
    <p>When you sign up for CAPITAL CONSULTANT, we may collect personal information such as your first and last name, phone number, email address, and any other information you provide us with. Additionally, we may collect usage data such as login times and IP addresses.</p>

    <h6>How We Use Your Information</h6>
    <p>We use your personal information to provide you with our services, to communicate with you about updates and information related to our platform and to customize your experience on our site.</p>
    <h6>Sharing of Information</h6>
    <p>We do not share your personal information with any third parties.</p>

    <h6>Security</h6>
    <p>CAPITAL CONSULTANT takes information security very seriously. We implement stringent measures to ensure your sensitive information is protected from third parties.</p>
    
    <h6>Data Retention</h6>
    <p>We keep your personal information for as long as necessary to provide our services and as required by law.</p>

    <h6>External Links</h6>
    <p> Our website may contain external links to other websites. We are not responsible for the content or privacy policies of these external sites.</p>

    <h6>Changes to Our Privacy Policy</h6>
    <p>We may update our Privacy Policy from time to time. Any changes to this policy will be reflected on our website.</p>

    <h6>Users Under Thirteen</h6>
    <p>Our website is not intended for users under the age of thirteen. We do not knowingly collect personal information from minors.</p>

    <p>In conclusion, CAPITAL CONSULTANT is committed to ensuring the privacy and security of our users. We appreciate your trust in our platform and welcome any questions or concerns regarding our privacy policy. Please reach out to us at any time.</p>
    </>
  )
}