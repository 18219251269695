import React, { useState } from "react";
// import { loadStripe } from "@stripe/stripe-js";
// import { Elements } from "@stripe/react-stripe-js";
// import CheckoutForm from "./CheckoutForm";
import { stripeCall } from "../../helpers/apis";

import { loadStripe } from '@stripe/stripe-js';
import {
    EmbeddedCheckoutProvider,
    EmbeddedCheckout
} from '@stripe/react-stripe-js';




const stripePromise = loadStripe('pk_test_51NoyKlAursW1G9pEJluCqSVwhowVRuKfab5ZBOQKwYBgmLO634GwNeES5AoPhjYXpgTwvMOWb3XWOKtWUHMNjgA3002j7Z0B5V');

function STRIPE(props) {

    async function asd() {
        try {

            const planMode = props.planType;
            const payload = {
                _id: props.user,
                mode: planMode
            };

            const stripeRes = await stripeCall(payload);

            if (!stripeRes?.link) throw new Error("Something went wrong");

            window.open(stripeRes?.link, "_self");

        } catch (error) {
            console.log(error);
        }
    }


    return (
        <div>
            <button className={props?.class || ""} onClick={() => asd()}>{props?.text || ""}</button>


            {/* <Elements stripe={stripePromise}>
        <CheckoutForm />
      </Elements> */}
        </div>
    );
}

export default STRIPE;