import React, { useEffect, useState } from 'react'
import Logo from "../assets/NEW/logo.png"
import { animate } from '../assets/animate'



export const Navbar = () => {
  const [authToken, setAuthToken] = useState( window.localStorage.getItem('token'))

  useEffect(() => {
    animate()

    // if (authToken != "" && authToken) IsLogin(authToken)
    
  }, [])

  return (
    <nav className="navbar navbar-expand-lg homeNav py-3" id="navbar">
      <div className="container">
        <a className="navbar-brand" href="/" >
          <img className='img-fluid' src={Logo} alt="logo" />
        </a>
        <button className="navbar-toggler custom-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon" />
        </button>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav me-auto mb-2 mb-lg-0">
            <li className="nav-item">
              <a className="nav-link" aria-current="page" href="/">HOME</a>
            </li>
            {/* <li className="nav-item dropdown">
              <a className="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false"> COURSES </a>
              <ul className="dropdown-menu">
                <li><a className="dropdown-item" href="#">Coming Soon</a></li>
              </ul>
            </li> */}

            <li className="nav-item">
              <a className="nav-link" href="/#opportunity">OPPORTUNITIES</a>
            </li>


            <li className="nav-item">
              <a className="nav-link" href="/#about">ABOUT</a>
            </li>

            <li className="nav-item">
              <a className="nav-link" href="/#faq">FAQ</a>
            </li>
            {
              (authToken !== "") &&
              <li className="nav-item">
                <a href='/positions' className="nav-link">POSITIONS</a>
              </li>
            }
            {
              (authToken !== "") &&
              <li className="nav-item">
                <a href='/portfolio' className="nav-link">PORTFOLIO</a>
              </li>
            }
          </ul>
          {
            authToken == "" || authToken == null ?
              <a href='/auth' className="getIt">Login</a>
              :
              <a href='/auth' className="getIt" onClick={() => { window.localStorage.setItem("token", ""); setAuthToken(""); }}>Sign Out</a>
          }
        </div>
      </div>
    </nav>
  )
}
