import { Modal } from "react-bootstrap";
import Logo from "../assets/NEW/logo.png"

import { useState } from "react";
import crossBlack from "../images/closeM.svg";
import { TOS } from "./landingPage/TOS";
import { Privacy } from "./landingPage/privacy";

export function Footer() {
    const [show, setShow] = useState(false);
    const [showPrivacy, setShowPrivacy] = useState(false);
    const handleClose = () => setShow(false);
  const handleShow = (e) => {
    e.preventDefault()
    setShow(true);
  }

    return <>
        <section className="footer">
            <div className="container">
                <div className="row mx-0">
                    <div className="col-md-4 col-sm-12 f1">
                        <img src={Logo} className="img-fluid" alt="logo" />
                        <p>Capital Consultant is a platform that provides expert guidance and education on the world of WEB3.0. The platform offers a range of resources, including educational sessions with industry experts, networking opportunities, and investment strategies.</p>
                    </div>
                    <div className="col-md-2 col-sm-6 col-6 f2">
                        <h5>Company</h5>
                        <a href="/#about">About</a>
                        <a href="/#opportunity">Opportunities</a>
                        <a href="/#testimonials">Testimonials</a>
                    </div>
                    {/* <div className="col-md-2 col-sm-6 col-6 f3">
                        <h5>Courses</h5>
                        <a href="#">Categories</a>
                        <a href="#">Coming Soon!!</a>
                    </div> */}
                    <div className="col-md-2 col-sm-6 col-6 f4">
                        <h5>Support</h5>
                        <a href="#faq">FAQ</a>
                        {/* <a href="#">Help Center</a> */}
                        <a href="" onClick={(e)=> {handleShow(e);setShowPrivacy(false)}}>Terms & Conditions</a>
                        <a href="" onClick={(e)=> {handleShow(e);setShowPrivacy(true)}}>Privacy Policy </a>
                    </div>
                    <div className="col-md-2 col-sm-6 col-6 f5">
                        <h5>Contact Info</h5>
                        <a href="#">info@capitalconsultant.co</a>
                    </div>
                </div>

                <div className="f12">
                    <span>Capital Consultant All Right Reserved, 2024</span>
                </div>
            </div>
        </section>

        <Modal className='privacyModal' show={show} onHide={handleClose}>
        <Modal.Body>
          <div className="privacyModalBody">
            <img
                onClick={handleClose}
                src={crossBlack}
                className={"corssBlackIco-modal"}
              />
              {showPrivacy?
                <Privacy />
              :
                <TOS />
              }
          </div>
        </Modal.Body>
      </Modal>
    </>
}