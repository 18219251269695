import React from 'react'

export const TOS = () => {
  return (
    <>
    <h5>TERMS OF CONDITIONS FOR CAPITAL CONSULTANT</h5>
    <p>Welcome to CAPITAL CONSULTANT, a platform designed to educate individuals about WEB3.0. By accessing and using our platform, you agree to the following Terms of Conditions:</p>

    <p><b>Eligibility:</b> By using our platform, you confirm that you are at least 18 years old and have the legal capacity to enter into a binding agreement. If you are under 18 years old, you may use our platform only with the involvement of a parent or legal guardian.</p>

    <p> <b>Content:</b> Our platform provides educational content related to WEB3.0. The information provided is for educational purposes only and does not constitute financial or investment advice. CAPITAL CONSULTANT does not guarantee the accuracy, completeness, or timeliness of the information provided and is not liable for any errors or omissions in the content. You are solely responsible for any actions you take based on the information provided.</p>
 
    <p><b>Live Sessions:</b> CAPITAL CONSULTANT provides Live Sessions with experts in the WEB3.0 industry. These sessions are for educational purposes only and do not constitute financial or investment advice. CAPITAL CONSULTANT does not guarantee the accuracy, completeness, or timeliness of the information provided in Live Sessions and is not liable for any errors or omissions in the content. You are solely responsible for any actions you take based on the information provided.</p>

    <p><b>Payment:</b> CAPITAL CONSULTANT offers various subscription packages for access to educational content and Live Sessions. By subscribing to a package, you agree to pay the fees associated with that package. CAPITAL CONSULTANT reserves the right to change the fees or subscription packages at any time.</p>

    <p><b>Intellectual Property:</b> All content on our platform, including text, images, graphics, videos, and logos, is the intellectual property of CAPITAL CONSULTANT or its content providers. You may not use, reproduce, or distribute any content from our platform without our prior written consent.</p>

    <p><b>Privacy Policy:</b> Your privacy is important to us. Our Privacy Policy outlines how we collect, use, and protect your personal information. By using our platform, you agree to our Privacy Policy.</p>

    <p><b>Indemnification:</b> You agree to indemnify, defend, and hold CAPITAL CONSULTANT harmless from any claims, damages, or losses arising from your use of our platform or your violation of these Terms of Conditions.</p>

    <p><b>Limitation of Liability:</b> CAPITAL CONSULTANT is not liable for any direct, indirect, incidental, special, or consequential damages arising from your use of our platform or your inability to use our platform.</p>

    <p><b>Modification of Terms:</b> CAPITAL CONSULTANT reserves the right to modify these Terms of Conditions at any time. Your continued use of our platform constitutes your acceptance of the modified Terms of Conditions.</p>

    <p><b>Governing Law and Dispute Resolution:</b> These Terms of Conditions are governed by and construed in accordance with the laws of [insert applicable jurisdiction]. Any disputes arising from these Terms of Conditions will be resolved through arbitration in [insert applicable city and state].</p>
     <p> By using our platform, you acknowledge that you have read and understood these Terms of Conditions and agree to be bound by them.</p>
   </>
  )
}