import { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { paymentQr } from '../../helpers/apis';
import { IoMdClose } from "react-icons/io";
import { CopyToClipboardButton } from '../copytoclipboard';



import { FaCheck } from 'react-icons/fa'
import { FaXmark } from "react-icons/fa6";
import Realistic from "react-canvas-confetti/dist/presets/realistic";
import { IoArrowBack } from "react-icons/io5";


const PaymentGateway = (props) => {
    const [qr, setQr] = useState();
    const [show, setShow] = useState(false);
    const [paymentInProcess, setPaymentInProcess] = useState(false);
    const [paymentReceive, setPaymentReceive] = useState(null);

    const handleClose = () => {
        setShow(false)
        setPaymentInProcess(false)
        setPaymentReceive(null)
    };
    const handleShow = () => setShow(true);

    console.log("props", props);

    const getQr = async () => {
        const qrCode = await paymentQr(props.planType);
        console.log("qrCode", qrCode);
        if (!qrCode?.error) setQr(qrCode);
        else setQr(null);
    };

    useEffect(() => {
        getQr();

    }, []);


    // 

    useEffect(() => {
        props.socket.on('payment_receive', (subscription) => {
            console.log("payment_receive", subscription);
            setPaymentReceive(subscription)
        });

        return () => {
            props.socket.off('payment_receive');
        };

    }, []);

    return <>
        <div>
            <button className={props.class} style={{ width: "90%" }} onClick={handleShow}> Subscribe Now </button>
        </div>

        <Modal className='payment-modal' centered show={show} onHide={handleClose}>
            <Modal.Header style={{ backgroundColor: paymentReceive == "failed" && paymentInProcess == true ? "var(--bs-danger)" : "var(--header)", color: "white" }}>
                <Modal.Title className='w-100'>
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                        {
                            !paymentInProcess ?
                                <span style={{ textTransform: "capitalize" }}>{props.planType} Plan USD</span>
                                :
                                paymentReceive == "failed" ?
                                    <span style={{ textTransform: "capitalize" }}>Payment Failed</span>
                                    :
                                    paymentReceive == "monthly" || paymentReceive == "yearly" ?
                                        <span style={{ textTransform: "capitalize" }}>Payment Receive</span>
                                        :
                                        <span style={{ textTransform: "capitalize" }}>I've Deposited</span>
                        }
                        <span onClick={handleClose} style={{ cursor: "pointer" }}> <IoMdClose /> </span>
                    </div>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>

                <div className='text-center'>
                    {
                        qr && !paymentInProcess ?
                            <>
                                <h2 className={"text-center mb-0"}>
                                    {props.planAmount} USDT - TRC20
                                </h2>

                                <div style={{ width: "200px", margin: "auto" }}>
                                    <img className='img-fluid' src={qr?.image} alt="QR Code" />
                                </div>
                                <p style={{ display: "flex", justifyContent: "center", gap: 10 }}>
                                    {qr?.address?.substring(0, 10) + "....." + qr?.address?.substring(qr.address.length - 10)}
                                    <CopyToClipboardButton text={qr?.address} />
                                </p>

                                <button className='btn green_button mx-auto' style={{ width: "90%" }} onClick={() => setPaymentInProcess(true)}> I've Deposited </button>
                            </>
                            :
                            qr && paymentInProcess ?
                                <>
                                    {
                                        paymentReceive ?
                                            <ReceivePayment paymentReceive={paymentReceive} />
                                            :
                                            <>
                                                <Timer />

                                                <Button variant="dark" onClick={() => setPaymentInProcess(false)}> <IoArrowBack /> Back to QR Code </Button>
                                            </>
                                    }
                                </>
                                :
                                <p>Loading QR Code...</p>
                    }
                </div>

            </Modal.Body>

            {/* <Modal.Footer>
                <Button variant="dark" onClick={handleClose}> Close </Button>
            </Modal.Footer> */}

        </Modal>

    </>
}


const Timer = () => {
    const [seconds, setSeconds] = useState(300);

    useEffect(() => {

        let timer = setInterval(() => {
            setSeconds(prevSeconds => prevSeconds - 1);
        }, 1000);

        if (seconds == 0) clearInterval(timer); // as seconds reach to zero clearInterval runs and timer stops

        return () => clearInterval(timer);


    }, [seconds]);

    const formatTime = (totalSeconds) => {
        const hours = Math.floor(totalSeconds / 3600);
        const minutes = Math.floor((totalSeconds % 3600) / 60);
        const secs = totalSeconds % 60;

        return [
            hours.toString().padStart(2, '0'),
            minutes.toString().padStart(2, '0'),
            secs.toString().padStart(2, '0')
        ]
    };

    return (
        <div>
            <div id="timer">

                <div className="timer-col">
                    <span className='span_1'> {(formatTime(seconds))[1]} </span>
                    <span className='span_2'> Minutes </span>
                </div>
                <div className="timer-col">
                    <span className='span_1'>&nbsp;:&nbsp;</span>
                </div>
                <div className="timer-col">
                    <span className='span_1'> {(formatTime(seconds))[2]} </span>
                    <span className='span_2'> Seconds </span>
                </div>

            </div>

        </div>
    );
};



const ReceivePayment = ({ paymentReceive }) => {

    const [Conductor, setConductor] = useState(0)
    const onInit = ({ conductor }) => {
        setConductor(conductor);
    };
    useEffect(() => {
        if (Conductor) {
            Conductor?.shoot();
            Conductor?.shoot();
        }

        if (paymentReceive != "failed") {
            setTimeout(() => {
                window.location.reload()

            }, 5000);
        }

    }, [Conductor])


    return <>

        {
            paymentReceive != 'failed' ? // means it's monthly or yearly subscription
                <>
                    <div style={{
                        width: "130px", height: "130px", display: "flex", justifyContent: "center", alignItems: "center", borderRadius: "100%",
                        background: "radial-gradient(circle, rgba(48,149,107,0.8856792717086834) 42%, rgba(79,191,144,0.8688725490196079) 69%, rgba(255,255,255,1) 92%, rgba(255,255,255,1) 100%)",
                        margin: "30px auto"
                    }}>

                        <FaCheck className="text-white" style={{ fontSize: "60px" }} />

                    </div>

                    <Realistic style={{
                        position: 'absolute',
                        maxWidth: '600px',
                        maxHeight: '400px',
                        width: '100%',
                        height: '100%',
                        pointerEvents: 'none',
                        top: 0,
                        left: 0,
                    }} onInit={onInit} />

                    {
                        paymentReceive == 'monthly' ?
                            <p style={{ color: "var(--textPrimaryColor)", fontWeight: 500, marginTop: 20 }}>We have received your payment for the
                                <span style={{ color: "var(--header)", fontWeight: 600 }}>  Monthly Subscription🎉 </span>
                                <br />
                                Your <span style={{ color: "var(--header)", fontWeight: 600 }}> SUBSCRIPTION IS ACTIVATED </span>. Thank you for your continued support!
                            </p>
                            :
                            <p style={{ color: "var(--textPrimaryColor)", fontWeight: 500, marginTop: 20 }}>We have received your payment for the
                                <span style={{ color: "var(--header)", fontWeight: 600 }}>  Yearly  Subscription🎉 </span>
                                <br />
                                Your <span style={{ color: "var(--header)", fontWeight: 600 }}> SUBSCRIPTION IS ACTIVATED </span>. Enjoy a full year of uninterrupted service!
                            </p>

                    }

                </>
                :
                <>
                    <div style={{
                        width: "130px", height: "130px", display: "flex", justifyContent: "center", alignItems: "center", borderRadius: "100%",
                        background: "radial-gradient(circle, rgba(220,53,69,1) 42%, rgba(255,101,101,1) 69%, rgba(255,255,255,1) 92%, rgba(255,255,255,1) 100%)",
                        margin: "30px auto"
                    }}>

                        <FaXmark className="text-white" style={{ fontSize: "60px" }} />
                    </div>


                    <p style={{ color: "var(--textPrimaryColor)", fontWeight: 500, marginTop: 20 }}>We encountered an issue
                        <span style={{ color: "var(--bs-danger)", fontWeight: 600 }}>  Processing Your Payment </span> for the Subscription.
                        <br />
                        Your subscription is currently<span style={{ color: "var(--bs-danger)", fontWeight: 600 }}> INACTIVE </span>.
                        Please DEPOSIT again to reactivate your subscription.
                    </p>
                </>
        }

    </>
}




export default PaymentGateway;