import { useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { signin_user, verifyAccount } from "../../helpers/apis";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { AuthSlider } from "./slider";
import Logo from "../../assets/NEW/logo.png"
import { useMediaQuery } from 'react-responsive'
import { Form, FloatingLabel, Row, Col, Button, Modal } from 'react-bootstrap';


export function VerifyAccount() {
    const { email } = useParams()
    const navigate = useNavigate()
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 992px)' })

    const [otp, setOtp] = useState()


    async function Submit(e) {
        e.preventDefault()

        verifyAccount({ otp, email })
            .then(data => {
                if (!data.success) return notify(data.message, toast.error)
                notify(data.message, toast.success)

                setTimeout(() => {
                    return navigate("/auth#signin")
                }, 2000);
            })
            .catch(err => {
                console.log(err.response.data);
                return notify(err.response.data, toast.error)
            })
    }


    const notify = (response, types) =>
        types(response, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });



    return <div className="qw123">
        <ToastContainer style={{ width: "max-content" }} />



        <div className={`${isTabletOrMobile ? "container-fluid" : "container"}  `}>
            <div className="row">
                <div className="col-12 col-lg-6">
                    <Form className="login" onSubmit={(e) => Submit(e)}>
                        <div className="mb-3 text-center">
                            <img src={Logo} alt="Logo" width={"150px"} />
                        </div>
                        <div className="mb-3 text-center row">
                            <h1 style={{ color: "var(--textPrimaryColor)" }}>OTP Verification</h1>
                        </div>

                        <FloatingLabel controlId="OTP" label="Enter OTP" className="mb-3 row">
                            <Form.Control
                                type="text"
                                value={otp} 
                                onChange={(e) => setOtp(e.target.value)}
                                placeholder="Enter OTP"
                                required
                            />
                        </FloatingLabel>

                        <div className="mb-3 row text-end">
                        </div>
                        <div className="mb-3">
                            <div className="d-flex justify-content-center">
                                <button className="btn green_button">Verify Now</button>
                            </div>
                            <div className="my-3 wq24">
                                Don't have an account?
                                <a href={"/auth#signup"} style={{ color: "var(--textPrimaryColor)" }}>Signup</a>
                            </div>
                        </div>
                    </Form>
                </div>
                <div className={`${isTabletOrMobile && "d-none"} col col-lg-6 `}>
                    <AuthSlider />
                </div>
            </div>
        </div>
    </div>
}