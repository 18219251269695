import React, { useRef, useState } from 'react';
import 'react-modal-video/css/modal-video.min.css';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import ReactImageVideoLightbox from "react-image-video-lightbox";

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import './testimonials.css';

// import required modules
import { Pagination, Autoplay, Navigation } from 'swiper/modules';
import testimonail1 from "../../assets/NEW/testimonial1.png"
import testimonail2 from "../../assets/NEW/testimonial2.png"

// import video2 from "../../images/educationVideo/video2.mp4"
// import video3 from "../../images/educationVideo/video3.mp4"
// import video4 from "../../images/educationVideo/video4.mp4"
// import video5 from "../../images/educationVideo/video5.mp4"
import ModalVideo from 'react-modal-video';



function EducationalVideos() {
    const progressCircle = useRef(null);
    const progressContent = useRef(null);
    const onAutoplayTimeLeft = (s, time, progress) => {
        progressCircle.current.style.setProperty('--progress', 1 - progress);
        progressContent.current.textContent = `${Math.ceil(time / 1000)}s`;
    };

    const [isOpen, setOpen] = useState(false);
    const [currentIndex, setCurrentIndex] = useState(0);

    const openVideo = () => {
        setOpen(true)
    }
    const openlightbox = (index) => {
        console.log(index);
        setCurrentIndex(index);
        setOpen(true);
      };

    const videoData = [
        {
            title:"Start a Milliion Dollar Business",
            image:"",
            videoURL:"",
        },
        {
            title:"Gold Price Prediction",
            image:"",
            videoURL:"",
        },
        {
            title:"Bitcon Prediction 2023",
            image:"",
            videoURL:"",
        },
        {
            title:"2 Crore from Bitcoin Market Crash",
            image:"",
            videoURL:"",
        },
        {
            title:"Boost Your Income",
            image:"",
            videoURL:"",
        },
        {
            title:"2 Crore from Bitcoin Market Crash",
            image:"",
            videoURL:"",
        },
    ]
    const data = [
        {
          index: 0,
          url: `${window.location.origin}/assets/video1.mp4`,
          thumbnail: `${window.location.origin}/assets/videoPoster1.jpg`,
          type: "video"
        },
        {
          index: 1,
          url: `${window.location.origin}/assets/video2.mp4`,
          thumbnail: `${window.location.origin}/assets/videoPoster2.jpg`,
          type: "video"
        },
        {
          index: 2,
          url: `${window.location.origin}/assets/video3.mp4`,
          thumbnail: `${window.location.origin}/assets/videoPoster3.jpg`,
          type: "video"
        },
        {
            index: 3,
            url: `${window.location.origin}/assets/video4.mp4`,
            thumbnail: `${window.location.origin}/assets/videoPoster4.jpg`,
          type: "video"
        },
        {
            index: 4,
            url: `${window.location.origin}/assets/video5.mp4`,
            thumbnail: `${window.location.origin}/assets/videoPoster5.jpg`,
          type: "video"
        },
      
      ];
      
      console.log(window.location.origin);
    return <>
        <Swiper
            className="mySwiper py-5"
            autoplay={{
                delay: 5000,
                disableOnInteraction: false,
            }}
            pagination={{
                clickable: true,
            }}
            navigation={true}
            modules={[Autoplay, Pagination, Navigation]}
            onAutoplayTimeLeft={onAutoplayTimeLeft}
            slidesPerView={1}
            spaceBetween={10}
            breakpoints={{
                640: {
                  slidesPerView: 2,
                  spaceBetween: 20,
                },
                768: {
                  slidesPerView: 2,
                  spaceBetween: 40,
                },
                1024: {
                  slidesPerView: 3,
                  spaceBetween: 50,
                },
              }}
        >
  
            {data.map((item, index) => (
            <SwiperSlide key={index}>
                <div className="videoImgCard" onClick={() => openlightbox(index)}>
                    <div className="imgDiv">
                        <img src={item.thumbnail} alt="" className="img-fluid" />
                    </div>
                    <div className="title">2 Crore from Bitcoin Market Crash</div>
                </div>
            </SwiperSlide>
            )
            )}
     
           
            <div className="autoplay-progress" slot="container-end">
                <svg viewBox="0 0 48 48" ref={progressCircle}>
                    <circle cx="24" cy="24" r="20"></circle>
                </svg>
                <span ref={progressContent}></span>
            </div>
        </Swiper>

        {isOpen && (
        <ReactImageVideoLightbox
          data={data}
          startIndex={currentIndex}
          showResourceCount={true}
          onCloseCallback={() => setOpen(false)}
          onNavigationCallback={(currentIndex) =>
            console.log(`Current index: ${currentIndex}`)
        }
       
        />
      )}
    </>

}

export default EducationalVideos;