import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { Form, Row, Col, FloatingLabel } from 'react-bootstrap';
import { addSignal, getAllCreators, getSignal } from '../../helpers/apis';
import { CSVLink } from "react-csv";
import { IoMdDownload } from "react-icons/io";



function AddSignal() {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [data, setData] = useState({
        Crypto: "",
        Long_Short: "long",
        Entry_Date: "",
        Average_Price: "",
        Stop: "",
        Target: "",
        Recommended_Portfolio: "",
        creator: ""
    })

    async function submit() {
        const res = await addSignal(data);
        alert(res.message)
    }

    const [signal, setSignal] = useState([])
    const GetSignal = async () => {
        const res = await getSignal({});
        setSignal(res.message)
        console.log(res.message);
    }

    const [creators, setCreators] = useState([]);
    const GetAllCreators = async () => {
        const res = await getAllCreators();
        setCreators(res.message)
        console.log(res.message);
    }

    useEffect(() => {
        if (show) GetAllCreators()
        GetSignal()

    }, [show])



    return (
        <>
            <div style={{ width: "100%", display: "flex", justifyContent: "end", flexDirection: "row-reverse" }}>
                <CSVLink
                    filename={"Profit-Booked.csv"}
                    className="btn green_button mx-2"
                    style={{ height: "38px", padding: "5px 10px", borderRadius: "5px", fontWeight: 500 }}
                    data={signal.filter(e => e.status == "ended").map((item) => {
                        return {
                            "CRYPTO": (item.Crypto).toUpperCase(),
                            "TRADE TYPE": (item.Long_Short).toUpperCase(),
                            "ENTRY DATE": item.Entry_Date,
                            "ENTRY PRICE": (item.Average_Price),
                            "Exit PRICE": (item.Stop),
                            "Exit DATE": item.Exit_Date,
                            "PROFIT%": item.Profit ? (item.Profit).toUpperCase() : "---",
                            "CREATOR": item.creator || "NA",
                        }
                    })}>
                    Profit Booked <IoMdDownload />
                </CSVLink>

                <CSVLink
                    filename={"Ongoing-Signals.csv"}
                    className="btn green_button mx-2"
                    style={{ height: "38px", padding: "5px 10px", borderRadius: "5px", fontWeight: 500 }}
                    data={signal.filter(e => e.status == "ongoing").map((item) => {
                        return {
                            "CRYPTO": (item.Crypto).toUpperCase(),
                            "TRADE TYPE": (item.Long_Short).toUpperCase(),
                            "ENTRY DATE": item.Entry_Date,
                            "ENTRY PRICE": (item.Average_Price),
                            "STOP": (item.Stop),
                            "TARGET": (item.Target),
                            "PORTFOLIO % TO BE APPLIED": (item.Recommended_Portfolio).toUpperCase(),
                            "CREATOR": item.creator || "NA",
                        }
                    })}>
                    Ongoing Signals <IoMdDownload />
                </CSVLink>

                <button className='btn btn-dark' onClick={handleShow}>Add Signal</button>
            </div>

            <Modal show={show} onHide={handleClose} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>Add Signal</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <Form>
                        <Row className='my-4'>
                            <Col>
                                <FloatingLabel controlId="floatingInput" label="Crypto" className="mb-3">
                                    <Form.Control type="text" value={data.Crypto} onChange={(e) => setData({ ...data, "Crypto": e.target.value })} placeholder="Crypto" />
                                </FloatingLabel>
                            </Col>
                            <Col>
                                <FloatingLabel controlId="floatingSelect" label="TRADE TYPE" className="mb-3">
                                    <Form.Select className='p-3' value={data.Long_Short} onChange={(e) => setData({ ...data, "Long_Short": e.target.value })} >
                                        <option value="long">Long</option>
                                        <option value="short">Short</option>
                                        <option value="spot">Spot</option>
                                        <option value="swing">Swing</option>
                                    </Form.Select>
                                </FloatingLabel>
                            </Col>
                        </Row>
                        <Row className='my-4'>
                            <Col>
                                <FloatingLabel controlId="floatingDate" label="Entry Date" className="mb-3">
                                    <Form.Control className='p-3' type='date' value={data.Entry_Date} onChange={(e) => setData({ ...data, "Entry_Date": e.target.value })} placeholder="Entry Date" />
                                </FloatingLabel>
                            </Col>
                            <Col>
                                <FloatingLabel controlId="floatingAveragePrice" label="Entry Price" className="mb-3">
                                    <Form.Control className='p-3' value={data.Average_Price} onChange={(e) => setData({ ...data, "Average_Price": e.target.value })} placeholder="Entry Price" />
                                </FloatingLabel>
                            </Col>
                        </Row>
                        <Row className='my-4'>
                            <Col>
                                <FloatingLabel controlId="floatingStop" label="Stop" className="mb-3">
                                    <Form.Control className='p-3' value={data.Stop} onChange={(e) => setData({ ...data, "Stop": e.target.value })} placeholder="Stop" />
                                </FloatingLabel>
                            </Col>
                            <Col>
                                <FloatingLabel controlId="floatingTarget" label="Target" className="mb-3">
                                    <Form.Control className='p-3' value={data.Target} onChange={(e) => setData({ ...data, "Target": e.target.value })} placeholder="Target" />
                                </FloatingLabel>
                            </Col>
                        </Row>
                        <Row className='my-4'>
                            <Col>
                                <FloatingLabel controlId="floatingRecommendedPortfolio" label="Portfolio % to be applied" className="mb-3">
                                    <Form.Control
                                        className='p-3'
                                        value={data.Recommended_Portfolio}
                                        onChange={(e) => setData({ ...data, "Recommended_Portfolio": e.target.value })}
                                        placeholder="Portfolio % to be applied"
                                    />
                                </FloatingLabel>
                            </Col>
                        </Row>
                        <Row className='my-4'>
                            <Col>
                                <FloatingLabel controlId="floatingCreator" label="Enter creator name" className="mb-3">
                                    <Form.Select className='p-3' value={data.creator} onChange={(e) => setData({ ...data, "creator": e.target.value })} >
                                        <option value="">Select creator</option>
                                        {
                                            creators.length > 0 && creators.map((creator, i) => {
                                                return <option key={i} value={creator}>{creator}</option>
                                            })
                                        }
                                    </Form.Select>

                                </FloatingLabel>
                            </Col>
                        </Row>
                    </Form>

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}> Close </Button>
                    <Button variant="dark" onClick={() => submit()}> Add </Button>
                </Modal.Footer>
            </Modal>

        </>
    );
}



export default AddSignal;