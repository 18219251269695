import React, { useState } from 'react'

import "./login.css"
import "../../assets/animate.css"
import { Navbar } from '../navbar';
import { Footer } from '../footer';

import Card1 from "../../assets/NEW/card1.png"
import Card2 from "../../assets/NEW/card2.png"
import Card3 from "../../assets/NEW/card3.png"
import Card4 from "../../assets/NEW/card4.png"
import Card5 from "../../assets/NEW/card5.png"
import Card6 from "../../assets/NEW/card6.png"
import Card7 from "../../assets/NEW/card7.png"
import WQ from "../../assets/NEW/wq.png"

import Testimonials from "../landingPage/testimonials"



export const Login = ({ notify, toast, getUser }) => {

  return (
    <div className='login'>
      <Navbar />

      <section className='l_sec2'>
        <div className="container">
          <div className="CAP">
            <div className='head1'>
              <span>Choose a Product</span>
              <span>to Begin</span>
            </div>
          </div>

          {/* <div className="row">
            <div className="col-md-4">
              <div className="cardsec">
                <div className="card c1">
                  <div className='img-card'>
                    <img src={Card1} className="card-img-top" alt="img" />
                  </div>
                  <div className="card-body">
                    <p className="card-title text-center">Trading INTRO COURSE</p>
                    <h6 className="card-title2 text-center">Verified Trading Guide</h6>
                    <a href="/auth" className="btn green_button">Login</a>
                    <p className='aces'>Don't have access? Learn more here.</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="cardsec">
                <div className="card c1">
                  <div className='img-card'>
                    <img src={Card2} className="card-img-top" alt="img" />
                  </div>
                  <div className="card-body">
                    <p className="card-title text-center">SPOT TRADING COURSE</p>
                    <h6 className="card-title2 text-center">SPOT Trading Guide</h6>
                    <a href="/auth" className="btn green_button">Login</a>
                    <p className='aces'>Don't have access? Learn more here.</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="cardsec">
                <div className="card c1">
                  <div className='img-card'>
                    <img src={Card3} className="card-img-top" alt="img" />
                  </div>
                  <div className="card-body">
                    <p className="card-title text-center">FUTURE Trading COURSE</p>
                    <h6 className="card-title2 text-center">FUTURE Trading Guide</h6>
                    <a href="/auth" className="btn green_button">Login</a>
                    <p className='aces'>Don't have access? Learn more here.</p>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
          <div className="row" style={{ justifyContent: "center" }}>

            <div className="col-lg-4 col-md-6 col-sm-12 mb-3">
              <div className="cardsec" data-aos="flip-left" data-aos-duration="1500">
                <div className="card">
                  <div className='img-card'>
                    <img src={Card1} className="card-img-top" alt="img" />
                  </div>
                  <div className="card-body">
                    <p className="card-title text-center" style={{ fontWeight: "500" }} >MONTHLY GROUP</p>
                    <h6 className="card-title text-center" style={{ fontWeight: "700" }}>Complete Trading Guide</h6>
                    <p className="card-text text-center c3421">Give yourself every advantage to excel and succeed in WEB3.0 through the knowledge, skills, and behind-the-scenes information jam-packed in this dynamic new course!</p>
                    <a href="/auth" className="btn green_button">Get it now - Just $30/Month.</a>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 col-sm-12 mb-3">
              <div className="cardsec" data-aos="flip-left" data-aos-duration="2000">
                <div className="card">
                  <div className='img-card'>
                    <img src={Card2} className="card-img-top" alt="img" />
                  </div>
                  <div className="card-body">
                    <p className="card-title text-center" style={{ fontWeight: "500" }} >YEARLY GROUP</p>
                    <h6 className="card-title text-center" style={{ fontWeight: "700" }}>Complete Trading Guide</h6>
                    <p className="card-text text-center c3421"> Unleash your full potential and achieve success in the world of WEB3.0 with this comprehensive and cutting-edge course. Packed with invaluable knowledge, essential skills, and exclusive insider information.</p>
                    <a href="/auth" className="btn green_button">Get it now - Just $300/Year.</a>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </section>

      {/* <section className="sec6">
        <div className="main">
          <h1>Join and Get 30 Days free Class</h1>
          <div className="join">
            <input type="email" placeholder='Your Email here..' />
            <button className="btn green_button">Get Started Free</button>
          </div>
        </div>
      </section> */}

      <Footer />
    </div>
  )
}