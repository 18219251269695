import Table from 'react-bootstrap/Table';
import { deletePost, getPostAdmin, updateOrders } from '../../helpers/apis';
import { useEffect, useReducer, useState } from 'react';
import { FaTrash } from "react-icons/fa"
import EditPosts from './editPosts';
import { T2D } from "../../helpers/time2date";
import { TiArrowSortedDown, TiArrowSortedUp, TiArrowUnsorted } from "react-icons/ti";
import { Paginate } from '../pagination';


const API_URL = process.env.REACT_APP_API_URL;



function Posts() {
    const urlParams = new URLSearchParams(window.location.search);
    const thisPage = urlParams.get('page');

    const [totalItems, setTotalItems] = useState(0)
    const [perPage, setPerPage] = useState(0)

    const [data, setData] = useState([]);
    const [newPositions, setNewPositions] = useState([]);  //  Show edit posts position btn or not
    const [, forceUpdate] = useReducer(x => x + 1, 0);

    const GetPosts = async (query) => {
        const res = await getPostAdmin(query);
        if (res.success) {
            setData(res.message)
            setTotalItems(res.totalPosts)
            setPerPage(res.perPage)
        }
    }

    async function deleteThis(id) {
        const confirm = window.confirm("You sure want to delete this post?")
        if (confirm) {
            const res = await deletePost(id)
            alert(res.message)
        }
    }


    const handlePositions = (item, position, action) => {
        let _data = data;

        if (action == "up") {
            const itemOrder = item.order
            const nextOrder = _data[position - 1]["order"]

            console.log("itemOrder", itemOrder);
            console.log("nextOrder", nextOrder);

            _data[position]["order"] = nextOrder;
            _data[position - 1]["order"] = itemOrder;
            _data.sort((a, b) => b.order - a.order);

            setNewPositions([
                ...newPositions,
                { _id: _data[position]["_id"], order: _data[position]["order"] },
                { _id: _data[position - 1]["_id"], order: _data[position - 1]["order"] }
            ])

            setData(_data);


        } else {
            const itemOrder = item.order
            const nextOrder = _data[position + 1]["order"]

            console.log("itemOrder", itemOrder);
            console.log("nextOrder", nextOrder);

            _data[position]["order"] = nextOrder;
            _data[position + 1]["order"] = itemOrder;
            _data.sort((a, b) => b.order - a.order);

            setData(_data);
            setNewPositions([
                ...newPositions,
                { _id: _data[position]["_id"], order: _data[position]["order"] },
                { _id: _data[position + 1]["_id"], order: _data[position + 1]["order"] }
            ])
        }
        forceUpdate();
    }

    const updatePositions = async () => {
        if (newPositions.length == 0) return;

        const res = await updateOrders(newPositions)
        if (res.success) { }
        setNewPositions([])
        alert(res.message)
    }


    useEffect(() => {
        
        localStorage.setItem("lastPage", window.location.hash)
        GetPosts(localStorage.getItem("lastPage") == window.location.hash ? `page=${thisPage}` : `page=${0}`)

    }, [thisPage])



    return (
        <>
            {
                newPositions.length > 0 &&
                <button className="btn btn-primary" onClick={() => updatePositions()}>Save Positions</button>
            }

            <section className='signal-table'>

                <Table hover responsive className='custom-table' size='xl'>
                    <thead>
                        <tr>
                            <th>Order</th>
                            <th>Profile</th>
                            <th>Username</th>
                            <th>Status</th>
                            <th>Post</th>
                            <th>Poster</th>
                            <th>PostType</th>
                            <th className='w-100 custom-column'>Tweet</th>
                            <th>Creator</th>
                            <th>Timestamp</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            data.map((item, i) => {
                                // if (i < 1) {
                                return <tr key={item._id}>
                                    {
                                        data.length <= 2 ?
                                            <>
                                                {
                                                    i == 0 ?
                                                        <td><TiArrowSortedDown /></td>
                                                        :
                                                        <td><TiArrowSortedUp /></td>
                                                }
                                            </>
                                            :
                                            <>
                                                {
                                                    i == 0 ?
                                                        <td><TiArrowSortedDown className='up' onClick={() => handlePositions(item, i, "down")} /></td>
                                                        :
                                                        i < data.length - 1 ?
                                                            <>
                                                                <td style={{ display: "flex", flexDirection: "column" }}>
                                                                    <TiArrowSortedUp className='down' onClick={() => handlePositions(item, i, "up")} />
                                                                    <TiArrowSortedDown className='up' onClick={() => handlePositions(item, i, "down")} />
                                                                </td>
                                                            </>
                                                            :
                                                            <td><TiArrowSortedUp className='down' onClick={() => handlePositions(item, i, "up")} /></td>
                                                }
                                            </>
                                    }
                                    <td>
                                        {
                                            typeof item.userID != "undefined" &&
                                                typeof item.userID.profile != "undefined" &&
                                                item.userID.profile != null &&
                                                item.userID.profile != "" ?
                                                <img width={"50px"} src={`${API_URL}/static/${item.userID.profile}`} alt="poster" /> :
                                                "---"
                                        }
                                    </td>
                                    <td> {item.userID.username}</td>
                                    <td> {item.status}</td>
                                    <td> {item.Post || "---"}</td>
                                    <td>
                                        {
                                            typeof item.Poster != "undefined" &&
                                                item.Poster != null &&
                                                item.Poster != "" ?
                                                <img width={"50px"} src={`${API_URL}/static/${item.Poster}`} alt="poster" /> :
                                                "---"
                                        }
                                    </td>
                                    <td> {item.PostType || "---"}</td>
                                    <td className='w-100 custom-column'> {item.tweet}</td>
                                    <td> {item.creator}</td>
                                    <td> {T2D(item.timestamp)}</td>
                                    <td>
                                        <EditPosts data={item} />
                                        <FaTrash style={{ cursor: "pointer" }} onClick={() => deleteThis(item._id)} />
                                    </td>
                                </tr>
                                // }
                            })
                        }
                    </tbody>
                </Table>


                <Paginate totalItems={totalItems} itemsPerPage={perPage} redirectTo={"#posts"} />

            </section>
        </>
    );
}


export default Posts;