import { Table, InputGroup, Form, Button, Modal, ModalBody } from 'react-bootstrap';
import { getPost, deletePost, getAllUser, changeUserStatus, activateSubscription, deactivateSubscription, setAsAdmin } from '../../helpers/apis';
import { useEffect, useState } from 'react';
import { T2D } from "../../helpers/time2date";
import { SlOptionsVertical } from "react-icons/sl";
import moment from 'moment';
import { Paginate } from '../pagination';
import crossBlack from "../../images/closeM.svg";
import UserPayments from './userPayments';

const API_URL = process.env.REACT_APP_API_URL;



function Users({ user }) {
    const urlParams = new URLSearchParams(window.location.search);
    const thisPage = urlParams.get('page');

    const [searchEmail, setSearchEmail] = useState("")
    const [totalItems, setTotalItems] = useState(0)
    const [perPage, setPerPage] = useState(0)
    const [data, setData] = useState([{
        _id: "",
        username: "",
        email: "",
        status: "",
        role: "",
        timestamp: "",
        subscription: "INACTIVE"
    }])

    const [selectedUserId, setSelectedUserId] = useState();

    const ALlUsers = async (query) => {
        const res = await getAllUser(query)
        if (res.success) {
            setData(res.message)
            setTotalItems(res.totalUsers)
            setPerPage(res.perPage)
        }
    }

    async function ChangeUserStatus(id, status) {
        const conf = window.confirm("Do you want to " + status + " this user?");
        if (conf) {
            const res = await changeUserStatus({ id, status })
            alert(res.message)
            if (res.success) ALlUsers()
        }
    }

    async function ActivateSubscription(id, status) {
        const conf = window.confirm("Do you want to Activate Subscription for this user?");
        if (conf) {
            const res = await activateSubscription({ userID: id, subs: status })
            alert(res.message)
            if (res.success) ALlUsers()
        }
    }

    async function DeactivateSubscription(id) {
        const conf = window.confirm("Do you want to Deactivate Subscription for this user?")
        if (conf) {
            const res = await deactivateSubscription({ userID: id })
            alert(res.message)
            if (res.success) ALlUsers()
        }
    }

    async function ChangeUserRole(id, role) {
        const conf = window.confirm("Do you want to make this user as admin?")
        if (conf) {
            const res = await setAsAdmin({ _id: id, role })
            alert(res.message)
            if (res.success) ALlUsers()
        }
    }

    const isSubsActive_Style = (subs) => {
        if (subs != null && typeof subs != "object" && (subs)?.toUpperCase() == "ACTIVE") return { color: "var(--textSecondColor)", fontWeight: 600, textTransform: "uppercase" }
        else return { color: "var(--bs-danger)", fontWeight: 600, textTransform: "uppercase" }
    }

    function getExpiryDate(date, plantTpe) {
        // Ensure Moment.js is available
        if (!moment) throw new Error('Moment.js library is required. Please include it in your project.');
        if (!date) return console.log('date not found.');
        // console.log("date", date);
        // Create a Moment object from the passed date
        const dateMoment = moment(date);

        // Add 1 month to the date
        dateMoment.add(1, plantTpe == "yearly" ? "years" : "months");

        // Set the date to the same day of the month (if it exists in the next month)
        dateMoment.date(date.getDate());

        // Return the formatted date string
        return dateMoment.format('YYYY-MM-DD'); // Or any desired format
    }

    const searchUser = async (e) => {
        e.preventDefault();

        if (searchEmail == "") return ALlUsers(localStorage.getItem("lastPage") == window.location.hash ? `page=${thisPage}` : `page=${0}`)

        const res = await getAllUser(`email=${searchEmail}`)
        if (res.success) {
            setData(res.message)
            setTotalItems(res.totalUsers)
            setPerPage(res.perPage)
        }
    }

    const ShowPayments = (userId) => {
        setSelectedUserId(userId);
    };

    const handleClose = () => {
        setSelectedUserId(null);
    };

    useEffect(() => {
        ALlUsers(localStorage.getItem("lastPage") == window.location.hash ? `page=${thisPage}` : `page=${1}`)
        localStorage.setItem("lastPage", window.location.hash)

    }, [thisPage])



    return (
        <>
            <div className='row justify-content-end'>
                <div className="col-lg-4 col-md-6 col-sm-8 ">
                    <form onSubmit={(e) => searchUser(e)} className='d-flex'>
                        <input type="text" className='form-control' value={searchEmail} onChange={(e) => setSearchEmail(e.target.value)} placeholder='Enter user email' style={{ height: "40px" }} />
                        <button className='btn btn-dark mb-0' >Search</button>
                    </form>
                </div>
            </div>

            <br />

            <section className='signal-table'>
                <Table hover responsive style={{ minHeight: "300px" }}>
                    <thead>
                        <tr>
                            <th>Username</th>
                            <th>Email</th>
                            <th>Status</th>
                            <th>Subscription</th>
                            <th>Whatsapp</th>
                            <th>Country</th>
                            <th>Expiry</th>
                            <th>Role</th>
                            <th>Created At</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            data.map((item, i) => {
                                return <tr key={item._id}>
                                    <td> {item.username}</td>
                                    <td> {item.email}</td>
                                    <td style={isSubsActive_Style(item.status)}> {item.status}</td>
                                    <td style={isSubsActive_Style(item.subscription)}> {item.subscription ? item.subscription : "INACTIVE"}</td>
                                    <td>{item.whatsapp || "---"}</td>
                                    <td>{item.country || "---"}</td>
                                    <td>
                                        {
                                            (item.subscription)?.toUpperCase() == "ACTIVE" ?
                                                getExpiryDate(new Date(item?.subs_data?.startDate), item?.subs_data?.planType)
                                                :
                                                "---"
                                        }
                                    </td>
                                    <td> {item.role}</td>
                                    <td> {T2D(item.timestamp)}</td>
                                    <td>
                                        {
                                            (item.email != "admin@capitalconsultant.co" && item.email != "admin@gmail.com") &&
                                            <div className="btn-group dropstart">
                                                <button type="button" className="btn" data-bs-toggle="dropdown" aria-expanded="false"><SlOptionsVertical /></button>
                                                <ul className="dropdown-menu">
                                                    <li> <a className="dropdown-item" href="#users" onClick={() => ChangeUserRole(item._id, "user")}>Set as User</a></li>
                                                    <li><a className="dropdown-item" href="#users" onClick={() => ChangeUserRole(item._id, "admin")}>Set as Admin</a></li>
                                                    <li><a className="dropdown-item" href="#users" onClick={() => ChangeUserStatus(item._id, "active")}>Activate User</a></li>
                                                    <li><a className="dropdown-item" href="#users" onClick={() => ChangeUserStatus(item._id, "block")}>Deactivate User</a></li>
                                                    <li><a className="dropdown-item" href="#users" onClick={() => ActivateSubscription(item._id, "monthly")}>Activate Subs./month</a></li>
                                                    <li><a className="dropdown-item" href="#users" onClick={() => ActivateSubscription(item._id, "yearly")}>Activate Subs./year</a></li>
                                                    <li><a className="dropdown-item" href="#users" onClick={() => DeactivateSubscription(item._id)}>Deactivate Subs.</a></li>
                                                    <li><a className="dropdown-item" href="#users" onClick={() => ShowPayments(item._id)}>Show Payments.</a></li>
                                                </ul>
                                            </div>
                                        }
                                    </td>
                                </tr>
                            })
                        }
                    </tbody>
                </Table>

                <Paginate totalItems={totalItems} itemsPerPage={perPage} redirectTo={"#users"} />

            </section >
            <Modal className='userPayments' show={selectedUserId != null} onHide={handleClose}>
                <Modal.Body style={{ width: "auto" }}>
                    <div className="userPaymentsModalBody">

                        <div className='headings'>
                            <h4 className='mb-0'>Total Payments# {data?.length}</h4>
                            <img onClick={handleClose} src={crossBlack} className={"corssBlackIco-modal"} />
                        </div>

                        <UserPayments userId={selectedUserId} />
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
}


export default Users;