import _404 from './assets/NEW/404.svg';
import './App.css';
import './home.css';
import { LandingPage } from './component/landingPage/landingPage';
import { Login } from './component/login/login';
import { BrowserRouter, Routes, Route, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useEffect, useState } from 'react';
import { Welcome } from './component/welcome/welcome';
import { VerifiedInvestment } from "./component/verified-investing/index"

import AOS from "aos";
import "aos/dist/aos.css";
import { Register } from './component/register';
import { Dashboard } from './component/admin panel/dashboard';
import { VerifyAccount } from './component/register/verifyAccount';
import { Portfoilio } from './component/portfolio';
import axios from 'axios';
import { Forget } from './component/register/forget';
import { Reset } from './component/register/reset_password';



// SOCKET CONNECTION
// --------------------------
import io from 'socket.io-client';

// const API_URL = "http://localhost:5211";
const API_URL = process.env.REACT_APP_API_URL;
// const API_URL = "https://api.capitalconsultant.co";

const socket = io(API_URL); // Adjust URL if necessary

// ------------------------------
// SOCKET CONNECTION END



function App() {
  const [user, setUser] = useState({});

  axios.defaults.headers.common['pk2'] = window.localStorage.getItem("token")
  axios.defaults.headers.common['st'] = window.localStorage.getItem("st")

  const notify = (response, types) =>
    types(response, {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

  const getUser = (data) => {
    setUser(data)
  }


  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);

  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route exact={true} path="/" element={<LandingPage notify={notify} toast={toast} getUser={getUser} />} />
          <Route exact={true} path="/auth" element={<Register notify={notify} toast={toast} getUser={getUser} />} />
          <Route exact={true} path="/verify-account/:email" element={<VerifyAccount notify={notify} toast={toast} getUser={getUser} />} />
          <Route exact={true} path="/login" element={<Login notify={notify} toast={toast} getUser={getUser} />} />
          <Route exact={true} path="/forget-password" element={<Forget notify={notify} toast={toast} getUser={getUser} />} />
          <Route exact={true} path="/reset-password/:token" element={<Reset notify={notify} toast={toast} getUser={getUser} />} />
          <Route exact={true} path="/positions" element={<VerifiedInvestment notify={notify} toast={toast} getUser={getUser} socket={socket} />} />
          <Route exact={true} path="/portfolio" element={<Portfoilio notify={notify} toast={toast} getUser={getUser} />} />
          <Route exact={true} path="/admin/dashboard" element={<Dashboard notify={notify} toast={toast} getUser={getUser} />} />
          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </BrowserRouter>
      <ToastContainer style={{ width: "max-content" }} />
    </div>
  );
}

function PageNotFound() {
  return <div style={{ width: "100%", height: "100vh", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
    <img src={_404} alt="404 Not Found" style={{ maxWidth: "100%", maxHeight: "600px" }} />
    <button className='btn btn-success' onClick={() => window.location.href = "/"}> Go to Home Page </button>
  </div>
}

export default App;