import React, { useState } from 'react'

import "./landingPage.css"
import "../../assets/animate.css"
import { Navbar } from '../navbar';
import { Footer } from '../footer';

import Card1 from "../../assets/NEW/card1.png"
import Card2 from "../../assets/NEW/card2.png"
import Card3 from "../../assets/NEW/card3.png"
import Card4 from "../../assets/NEW/card4.png"
import Card5 from "../../assets/NEW/card5.png"
import Card6 from "../../assets/NEW/card6.png"
import Card7 from "../../assets/NEW/card7.png"
import WQ from "../../assets/NEW/wq.png"
import WQ1_1 from "../../assets/NEW/Waqar_Zaka-1 (1) 1.png"
// import slide1 from "../../assets/NEW/hero-section.png"
import slide1 from "../../assets/slides/bg2.png"
import image1 from "../../assets/slides/img2.png"
import mob_slide1 from "../../assets/NEW/mob-slide-1.png"
// import slide2 from "../../assets/NEW/Group-123-1000002886.png"
import slide2 from "../../assets/slides/bg3.png"
import image2 from "../../assets/slides/img3.png"
import mob_image2 from "../../assets/slides/mob-img2.png"
import mob_slide2 from "../../assets/NEW/mob-slide-2.png"
// import slide2 from "../../assets/NEW/Cryptocurrency31313123123.png"
import slide3 from "../../assets/slides/img1_2.png"
import slide3_1 from "../../assets/slides/img1_1.png"
// import slide3 from "../../assets/NEW/Banner-123-01-01 3.png"
// import slide3 from "../../assets/NEW/young-6746989.png"
import slide4 from "../../assets/slides/bg4.png"
import slide4_1 from "../../assets/slides/img4.png"

import Testimonials from "../landingPage/testimonials"
import EducationalVideos from './educationalVideos';
import Typist from 'react-typist';
import STRIPE from '../stripe/index';
import { useMediaQuery } from 'react-responsive'
import Carousel from 'react-bootstrap/Carousel';
import TradingViewWidget from './TradingViewWidget';
import CoinmarketcapWidgetMarquee from './coinmarketcap-widget-marquee';





export const LandingPage = ({ notify, toast, getUser }) => {
  const isSmallScreen = useMediaQuery({ query: '(max-width: 992px)' })
  const isSmallScreen2 = useMediaQuery({ query: '(max-width: 767px)' })
  const isSmallScreen3 = useMediaQuery({ query: '(max-width: 530px)' })

  return (
    <div className='landingPage2 '>
      <Navbar />

      <section className="HeroSection">

        <Carousel data-bs-theme="dark">
          <Carousel.Item>
            <div style={{ position: "relative" }} className='slider' >
              <img className="d-block img-fluid w-100" style={{ height: "var(--sliderHeight)" }} src={slide1} alt="slide 1" />

              <div className={`${isSmallScreen2 ? "" : "container"}`}>
                <div className={`row ${isSmallScreen2 ? "" : "d-flex ai-center jc-between row_reverse"} `} style={{ height: "100vh", position: "absolute", zIndex: 100000000000000, top: "0px" }}>
                  <div className={`col-md-6 content ${isSmallScreen2 ? "order-2" : ""}`}>
                    <p className='text-success text-uppercase'>Learn Trading With</p>
                    <h1 className='text-white text-uppercase'>Waqar Zaka</h1>
                    <button className='btn btn-success text-uppercase' onClick={() => window.location.href = "/auth"}>Enroll Course</button>
                  </div>
                  <div className='col-md-6 right_col'>
                    <img className='img-fluid side_image' src={image1} alt='image' />
                  </div>
                </div>
              </div>
            </div>
          </Carousel.Item>

          <Carousel.Item>
            <div style={{ position: "relative" }} className='slider' >
              <img className="d-block img-fluid" style={{ height: "var(--sliderHeight)" }} src={slide2} alt="slide 2" />

              <div className={`${isSmallScreen2 ? "" : "container"}`}>
                <div className={`row ${isSmallScreen2 ? "" : "d-flex ai-center jc-between row_reverse"} `} style={{ height: "100vh", position: "absolute", zIndex: 100000000000000, top: "0px" }}>
                  <div className={`col-md-6 content ${isSmallScreen2 ? "order-2" : ""}`}>
                    <h4 className='text-white text-uppercase'>Go Trade Like A</h4>
                    <h1 className='text-white text-uppercase'>Pro Trader</h1>
                    <button className='btn btn-success text-uppercase' onClick={() => window.location.href = "/auth"}>Enroll Course</button>
                  </div>
                  <div className='col-md-6 right_col'>
                    <img className='img-fluid side_image_2' src={isSmallScreen2 ? mob_image2 : image2} alt='image' />
                  </div>
                </div>
              </div>
            </div>
          </Carousel.Item>

          <Carousel.Item>
            <div style={{ position: "relative" }} className='slider slider_3' >
              <img className="d-block img-fluid w-100" style={{ height: "var(--sliderHeight)" }} src={slide3} alt="slide 3" />

              <div className={`${isSmallScreen2 ? "" : "container"}`}>
                <div className={`row ${isSmallScreen2 ? "" : "d-flex ai-center jc-between row_reverse"} `} style={{ height: "100vh", position: "absolute", zIndex: 100000000000000, top: "0px" }}>
                  <div className={`col-md-7 content ${isSmallScreen2 ? "order-2" : ""}`}>
                    <h1 className='text-white text-uppercase'>Learn <span className='text-success'>Trading From</span> The Experts</h1>
                    <div>
                      <img className='img-fluid mb-4 image3_1' src={slide3_1} alt='slide3_1' />
                    </div>
                    <button className='btn btn-success text-uppercase' onClick={() => window.location.href = "/auth"}>View Our Course</button>
                  </div>
                </div>
              </div>
            </div>
          </Carousel.Item>

          <Carousel.Item>
            <div style={{ position: "relative" }} className='slider slider_4' >
              <img className="d-block img-fluid w-100" style={{ height: "var(--sliderHeight)" }} src={slide4} alt="slide 4" />

              <div className={`${isSmallScreen2 ? "" : "container"}`}>
                <div className={`row ${isSmallScreen2 ? "" : "d-flex ai-center jc-between row_reverse"} `} style={{ height: "100vh", position: "absolute", zIndex: 100000000000000, top: "0px" }}>
                  <div className={`col-md-6 content ${isSmallScreen2 ? "order-2" : ""}`}>
                    <h2 className='text-white text-uppercase'><span className='text-success'>Learn</span> How To</h2>
                    <h1 className='text-white text-uppercase mb-0'>Profit</h1>
                    <h2 className='text-white text-uppercase mb-4'>Like A <span className='text-success'>Mastertrade</span></h2>
                    <button className='btn btn-success text-uppercase' onClick={() => window.location.href = "/auth"}>View Our Course</button>
                  </div>

                  <div className='col-md-6 right_col'>
                    <img className='img-fluid side_image' src={slide4_1} alt='image' />
                  </div>
                </div>
              </div>
            </div>
          </Carousel.Item>
        </Carousel>


        {/* <div className="row w-100 h-100" style={{ position: "absolute", zIndex: 100000000000000, top: "0px" }} >
          <div className="col-md-6">
            <div className='col1' data-aos="fade-zoom-in" data-aos-duration="1500">
              <p style={{ color: "var(--textSecondColor)" }}>
                <Typist stdTypingDelay={300}>
                  <p className='text-white d-inline'>Unlock The Potential Of</p>
                </Typist>
                <Typist stdTypingDelay={300}>
                  <Typist.Delay ms={3000} />
                  <span> Web 3.0 </span>
                </Typist>
                <Typist stdTypingDelay={300}>
                  <Typist.Delay ms={4500} />
                  <p className='text-white d-inline'>With Capital Consultant</p>
                </Typist>
              </p>
              <button className='btn' onClick={() => window.location.href = "/login"}>GET STARTED</button>
            </div>
          </div>
          <div className="col-md-6">
            <img src="" alt="" />
          </div>
        </div> */}
      </section>

      <section className='sec2' id='opportunity'>
        <div className="container">
          <div className="learnExpert" data-aos="fade-up" data-aos-duration="1500">
            <div className='head1'>
              <span>LEARN FROM THE</span>
              <span className='green'>EXPERTS</span>
            </div>
            <p className="desc">Capital Consultant provides you with access to Live Sessions from experts in the space. Our Live Sessions allow you to deepen your understanding of the complex topics related to cryptocurrency. With Capital Consultant, you'll get the knowledge you need to make informed decisions and take advantage of the potential financial gains.</p>
          </div>

          <div className="row" style={{ justifyContent: "center" }}>

            <div className="col-lg-4 col-md-6 col-sm-12 mb-3">
              <div className="cardsec" data-aos="flip-left" data-aos-duration="1500">
                <div className="card">
                  <div className='img-card'>
                    <img src={Card1} className="card-img-top" alt="img" />
                  </div>
                  <div className="card-body">
                    <p className="card-title text-center" style={{ fontWeight: "500" }} >MONTHLY GROUP</p>
                    <h6 className="card-title text-center" style={{ fontWeight: "700" }}>Complete Trading Guide</h6>
                    <p className="card-text text-center c3421">Give yourself every advantage to excel and succeed in WEB3.0 through the knowledge, skills, and behind-the-scenes information jam-packed in this dynamic new course!</p>
                    <a href="/auth" className="btn green_button">Get it now - Just $30/Month.</a>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 col-sm-12 mb-3">
              <div className="cardsec" data-aos="flip-left" data-aos-duration="2000">
                <div className="card">
                  <div className='img-card'>
                    <img src={Card2} className="card-img-top" alt="img" />
                  </div>
                  <div className="card-body">
                    <p className="card-title text-center" style={{ fontWeight: "500" }} >YEARLY GROUP</p>
                    <h6 className="card-title text-center" style={{ fontWeight: "700" }}>Complete Trading Guide</h6>
                    <p className="card-text text-center c3421"> Unleash your full potential and achieve success in the world of WEB3.0 with this comprehensive and cutting-edge course. Packed with invaluable knowledge, essential skills, and exclusive insider information.</p>
                    <a href="/auth" className="btn green_button">Get it now - Just $300/Year.</a>
                  </div>
                </div>
              </div>
            </div>

          </div>

          <div className="learnExpert" data-aos="fade-zoom-in" data-aos-duration="2000">
            <div className='head1'>
              <span>Why Capital Consultant?</span>
            </div>
            <p className="desc">Are you interested in the world of Web 3.0 but don't know where to start? Look no further than Capital Consultant. Our team of experts is dedicated to helping educate and guide you at every step of your WEB3.0 journey. Here are some of the benefits you can expectwhen you join our community:</p>
          </div>

          <div className="row">
            <div className="col-sm-6">
              <div className="card2" data-aos="fade-up" data-aos-duration="1500">
                <img src={Card4} alt="img" />
                <h6>Access a Growing Market</h6>
                <p>Web 3.0 is a rapidly expanding market, with new coins and technologies being developed all the time. Capital Consultant keeps a finger on the pulse of this market, and can help you make informed decisions about which coins to invest in.</p>
              </div>
            </div>
            <div className="col-sm-6">
              <div className="card2" data-aos="fade-up" data-aos-duration="2000">
                <img src={Card5} alt="img" />
                <h6>Access a Growing Market</h6>
                <p>Whether you are a beginner or an experienced WEB3.0 investor, we have the resources you need to stay ahead of the curve. Our team offers courses, webinars, and other educational materials to help you level up your skills and increase your financial returns.</p>
              </div>
            </div>
            <div className="col-sm-6">
              <div className="card2" data-aos="fade-up" data-aos-duration="1500">
                <img src={Card6} alt="img" />
                <h6>Networking opportunities</h6>
                <p>One of the best ways to learn about WEB3.0 is to talk to other investors and industry professionals. Capital Consultant offers networking events andonline communities where you can connect with others who share your interests and goals.</p>
              </div>
            </div>
            <div className="col-sm-6">
              <div className="card2" data-aos="fade-up" data-aos-duration="2000">
                <img src={Card7} alt="img" />
                <h6>Potential for exceptional financial gains</h6>
                <p>While WEB3.0 can be volatile, it also has the potential to generate incredible returns. Our team is dedicated to helping you make smart investments that can help you achieve financial freedom and build generational wealth.</p>
              </div>
            </div>
          </div>


          <h6 className='text-center mt-5' style={{ fontWeight: "700", color: "var(--textPrimaryColor)" }}> Why wait? Join Capital Consultant today and start unlocking the power of WEB3.0 for yourself.</h6>
          <button className='btn green_button m-auto mt-4 px-5' onClick={() => window.location.href = "/login"}>Get Started</button>
        </div>
      </section >

      <section className="sec7 ">

        <h1 className="homeHeading text-center mb-4">Crypto Heatmap</h1>

        <div className="row h-100">
          <div className="col-md-7">
            <div className="card">
              <gecko-coin-heatmap-widget locale="en" transparent-background="true" outlined="true" top="100" width="100%"></gecko-coin-heatmap-widget>
            </div>
          </div>
          <div className="col-md-5">
            <div className="card">
              <TradingViewWidget />
            </div>
          </div>
        </div>

      </section>

      <CoinmarketcapWidgetMarquee />

      <section className="sec3">
        <div className="row h-100">
          <div className="col-md-6">
            <div className="sec3img">
              <img src={WQ1_1} className='s3avatar' alt="avatar" data-aos="flip-left" data-aos-duration="1500" />
            </div>
          </div>
          <div className="col-md-6">
            <div className="content" data-aos="flip-left" data-aos-duration="1500">
              <h1>Meet Waqar Zaka</h1>
              <p>Waqar Zaka, did his Matric from Karachi’s top school, B.V.S Parsi High School, then Admajee science college, did his Civil Engineering from N.E.D University, his remarkable achievements speak volumes about his leadership and dedication. Time and time again, he has demonstrated his belief that a leader should lead from the front, rather than just calling for protests. Waqar Zaka's extraordinary courage and dedication are exemplified by his relentless pursuit of justice for the marginalized and oppressed. As a prominent influencer with a vast social media network, he utilizes his platform to raise awareness about modern technology and its implications. However, his impact goes far beyond content creation. Waqar Zaka is renowned for his unwavering support for rape victims, acid attack survivors, women facing cyberbullying, and, above all, his tireless...</p>
              <div className="socialLinks">
                <a className='facebook me-2' href="https://www.facebook.com/waqarzaka/" target="_blank" rel="noopener noreferrer">
                  <i className="fa-brands fa-facebook-f"></i>
                </a>
                <a className='twitter me-2' href="https://twitter.com/zakawaqar" target="_blank" rel="noopener noreferrer">
                  <i className="fa-brands fa-twitter"></i>
                </a>
                <a className='insta' href="https://www.instagram.com/waqarzaka/?hl=en" target="_blank" rel="noopener noreferrer">
                  <i className="fa-brands fa-instagram"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="sec4" data-aos="zoom-in" data-aos-duration="2500" id='about'>
        <h1>Know More about capital </h1>
        <span className='i88'>consultant</span>
        <p>Are you interested in the world of Web 3.0 but don't know where to start? Look no further than Capital Consultant. Our team of experts is dedicated to helping educate and guide you at every step of your WEB3.0 journey. Here are some of the benefits you can expectwhen you join our community:</p>

        <iframe width="100%" height="500px" src="https://www.youtube.com/embed/7wiKMPMRDxg?si=sFNKDtQaDPl5L213" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
      </section>


      {/* <div className="container">
        <section className="sec5 pt-0" data-aos="fade-right" data-aos-duration="1500">
          <h1>Our Education Videos</h1>

          <EducationalVideos />
        </section>
      </div> */}


      <div className="container">
        <section className="sec5 p-0" data-aos="fade-right" data-aos-duration="1500" id='testimonials'>
          <h1>What student’s say</h1>
          <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Praesentium unde hic placeat quia dicta voluptatem voluptatum</p>
          <Testimonials />
        </section>
      </div>

      {/* faq section */}
      {/* <section>
        <div className="container">
          <div className="faqSec" id='faq'>
            <h1>Frequently Asked Questions</h1>
            <div className="accordion" id="myAccordion">
              <div className="accordion-item animatable fadeInUpLong ">
                <h2 className="accordion-header" id="headingOne">
                  <button type="button" className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapseOne">What is WEB3.0?</button>
                </h2>
                <div id="collapseOne" className="accordion-collapse collapse" data-bs-parent="#myAccordion">
                  <div className="card-body">
                    <p>WEB3.0 refers to a digital or virtual currency that uses encryption techniques to regulate the generation of units and verify the transfer of funds. WEB3.0 operates independently of a central bank and can be transferred directly between individuals.</p>
                  </div>
                </div>
              </div>
              <div className="accordion-item animatable fadeInUpLong ">
                <h2 className="accordion-header" id="headingTwo">
                  <button type="button" className="accordion-button" data-bs-toggle="collapse" data-bs-target="#collapseTwo">What is Capital Consultant?</button>
                </h2>
                <div id="collapseTwo" className="accordion-collapse collapse show" data-bs-parent="#myAccordion">
                  <div className="card-body">
                    <p>Capital Consultant is a platform that provides expert guidance and education on the world of WEB3.0. The platform offers a range of resources, including educational sessions with industry experts, networking opportunities, and investment strategies.</p>
                  </div>
                </div>
              </div>
              <div className="accordion-item animatable fadeInUpLong ">
                <h2 className="accordion-header" id="headingThree">
                  <button type="button" className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapseThree">What kind of resources does Capital Consultant offer?</button>
                </h2>
                <div id="collapseThree" className="accordion-collapse collapse" data-bs-parent="#myAccordion">
                  <div className="card-body">
                    <p>Capital Consultant offers a wide range of educational resources, including live sessions with industry experts, tutorials, and investment strategies. The platform also offers networking opportunities, where members can connect with other investors and share their insights and experiences.</p>
                  </div>
                </div>
              </div>
              <div className="accordion-item animatable fadeInUpLong ">
                <h2 className="accordion-header" id="headingThree">
                  <button type="button" className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapseFour">Who is Capital Consultant for?</button>
                </h2>
                <div id="collapseFour" className="accordion-collapse collapse" data-bs-parent="#myAccordion">
                  <div className="card-body">
                    <p>Capital Consultant is for anyone who wants to learn more about WEB3.0 and how to invest in it. The platform is suitable for beginners as well as experienced investors who are looking to expand their knowledge and make smart investment decisions.</p>
                  </div>
                </div>
              </div>
              <div className="accordion-item animatable fadeInUpLong ">
                <h2 className="accordion-header" id="headingThree">
                  <button type="button" className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapseFive">How can Capital Consultant help me achieve financial freedom?</button>
                </h2>
                <div id="collapseFive" className="accordion-collapse collapse" data-bs-parent="#myAccordion">
                  <div className="card-body">
                    <p>Capital Consultant can help you achieve financial freedom by providing expert guidance and education on WEB3.0. With our resources and investment strategies, you can learn how to make smart decisions and earn exceptional financial gains.</p>
                  </div>
                </div>
              </div>
              <div className="accordion-item animatable fadeInUpLong ">
                <h2 className="accordion-header" id="headingThree">
                  <button type="button" className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapseSix">Is WEB3.0 a safe investment?</button>
                </h2>
                <div id="collapseSix" className="accordion-collapse collapse" data-bs-parent="#myAccordion">
                  <div className="card-body">
                    <p>WEB3.0 is a relatively new and volatile market, and like any investment, it comes with risks. However, with the right education and guidance, WEB3.0 can be a lucrative investment opportunity. Capital Consultant helps you navigate the risks and guide you towards smart investment decisions.</p>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </section> */}


      {/* Newsletter */}
      <div className='newsletter_section' style={{ background: "#183B56", padding: "100px" }}>
        <h1 className='text-white text-center'>Join and Get 30 Days free Class</h1>

        {
          !isSmallScreen3 ?
            <form className='newsletter' style={{ padding: "5px 15px", textAlign: "center", background: "white", width: "fit-content", margin: "auto" }}>
              <input type="text" placeholder='Your Email here...' className='d-inline' style={{ border: "none", width: "250px" }} />
              <button type="submit" className="btn green_button px-3 d-inline">Get Started Free</button>
            </form>
            :
            <form className='newsletter' style={{ textAlign: "center", width: "fit-content", margin: "auto" }}>
              <input type="text" placeholder='Your Email here...' className='d-inline mb-3' style={{ padding: "15px 15px", border: "none", width: "100%" }} />
              <button type="submit" className="btn green_button px-3 d-inline">Get Started Free</button>
            </form>
        }

      </div>

      <Footer />

    </div >
  )
}