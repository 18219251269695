import axios from "axios";
// const API_URL = "http://localhost:5211";
const API_URL = process.env.REACT_APP_API_URL;
//const API_URL = "https://api.capitalconsultant.co";



// Create_User
export const signup_user = async (body) => {
    try {
        const data = await axios.post(`${API_URL}/auth/sign-up`, body)
            .then(res => {
                console.log(res);
                return (res.data);
            })
        return data;
    }
    catch (err) {
        console.log(err.response.data);
        return err.response.data;
    }
}


// Verify_Account
export const verifyAccount = async (body) => {
    try {
        const data = await axios.post(`${API_URL}/auth/verify`, body)
            .then(res => {
                console.log(res);
                return (res.data);
            })
        return data;
    }
    catch (err) {
        console.log(err.response.data);
        return err.response.data;
    }
}


// Signin_User
export const signin_user = async (body) => {
    try {
        const data = await axios.post(`${API_URL}/auth/sign-in`, body)
            .then(res => {
                console.log(res);
                return (res.data);
            })
        return data;
    }
    catch (err) {
        console.log(err.response.data);
        return err.response.data;
    }
}


export const google_signin_user = async (body) => {
    try {
        const data = await axios.post(`${API_URL}/auth/sign-in-with-google`, body)
            .then(res => {
                console.log(res);
                return (res.data);
            })
        return data;
    }
    catch (err) {
        console.log(err.response.data);
        return err.response.data;
    }
}


// Forget_Password
export const forgetPassword = async (body) => {
    try {
        const data = await axios.post(`${API_URL}/auth/forget-password`, body)
            .then(res => {
                console.log(res);
                return (res.data);
            })
        return data;
    }
    catch (err) {
        console.log(err.response.data);
        return err.response.data;
    }
}


// Reset_Password
export const resetPassword = async (body) => {
    try {
        const data = await axios.post(`${API_URL}/auth/reset-password/${body.token}`, body)
            .then(res => {
                console.log(res);
                return (res.data);
            })
        return data;
    }
    catch (err) {
        console.log(err.response.data);
        return err.response.data;
    }
}


// getUserData
export const getUserData = async () => {
    try {
        // const data = await axios.post(`${API_URL}/auth/get`, { token })
        const data = await axios.post(`${API_URL}/auth/get`, {})
            .then(res => {
                console.log(res);
                return (res.data);
            })
        return data;
    }
    catch (err) {
        console.log(err.response.data);
        return err.response.data || [];
    }
}


// getAllUser
export const getAllUser = async (query) => {
    try {
        const data = await axios.get(`${API_URL}/auth/get-users?${query}`)
            .then(res => {
                console.log(res);
                return (res.data);
            })
        return data;
    }
    catch (err) {
        console.log(err.response.data);
        return err.response.data || [];
    }
}


// Add Post
export const addPost = async (body) => {
    try {
        const data = await axios.post(`${API_URL}/post/add`, body)
            .then(res => {
                console.log(res);
                return (res.data);
            })
        return data;
    }
    catch (err) {
        console.log(err.response.data);
        return err.response.data;
    }
}


// Get Post Admin
export const getPostAdmin = async (query) => {
    try {
        const data = await axios.get(`${API_URL}/post/get-admin?${query}`)
            .then(res => {
                console.log(res);
                return (res.data);
            })
        return data;
    }
    catch (err) {
        console.log(err.response.data);
        return err.response.data || [];
    }
}


// Get Post
export const getPost = async () => {
    try {
        const data = await axios.get(`${API_URL}/post/get`)
            .then(res => {
                console.log(res);
                return (res.data);
            })
        return data;
    }
    catch (err) {
        console.log(err.response.data);
        return err.response.data || [];
    }
}


// Edit Post
export const editPost = async (body) => {
    console.log((body))
    try {
        const data = await axios.post(`${API_URL}/post/update`, body)
            .then(res => {
                console.log(res);
                return (res.data);
            })
        return data;
    }
    catch (err) {
        console.log(err.response.data);
        return err.response.data;
    }
}


// Delete Post
export const deletePost = async (id) => {
    console.log((id))
    try {
        const data = await axios.post(`${API_URL}/post/delete/${id}`)
            .then(res => {
                console.log(res);
                return (res.data);
            })
        return data;
    }
    catch (err) {
        console.log(err.response.data);
        return err.response.data;
    }
}


// Update Posts Order
export const updateOrders = async (body) => {
    try {
        const data = await axios.post(`${API_URL}/post/update-orders/`, body)
            .then(res => {
                console.log(res);
                return (res.data);
            })
        return data;
    }
    catch (err) {
        console.log(err.response.data);
        return err.response.data;
    }
}

// Get Payment QR
export const paymentQr = async (planType) => {
    try {
        const data = await axios.get(`${API_URL}/subs/qr-code/${planType}`)
            .then(res => {
                console.log(res);
                return (res.data);
            })
        return data;
    }
    catch (err) {
        console.log(err.response.data);
        return err.response.data;
    }
}



// Add Signal
export const addSignal = async (body) => {
    try {
        const data = await axios.post(`${API_URL}/signal/add`, body)
            .then(res => {
                console.log(res);
                return (res.data);
            })
        return data;
    }
    catch (err) {
        console.log(err.response.data);
        return err.response.data;
    }
}


// Get Signal
export const getSignal = async (status, page) => {

    if (typeof page == "undefined") {
        const data = await axios.get(`${API_URL}/signal/get?page=1`, { params: status })
            .then(res => {
                console.log(res);
                return (res.data);
            })
        return data;

    } else {
        const data = await axios.get(`${API_URL}/signal/get?${page}`, { params: status })
            .then(res => {
                console.log(res);
                return (res.data);
            })
        return data;
    }



    try {
    }
    catch (err) {
        console.log(err.response.data);
        return err.response.data;
    }
}


// Edit Signal
export const editSignal = async (body) => {
    console.log((body))
    try {
        const data = await axios.post(`${API_URL}/signal/update`, body)
            .then(res => {
                console.log(res);
                return (res.data);
            })
        return data;
    }
    catch (err) {
        console.log(err.response.data);
        return err.response.data;
    }
}


// Delete Signal
export const deleteSignal = async (id) => {
    try {
        const data = await axios.post(`${API_URL}/signal/delete/${id}`)
            .then(res => {
                console.log(res);
                return (res.data);
            })
        return data;
    }
    catch (err) {
        console.log(err.response.data);
        return err.response.data;
    }
}


// Get All Subscription
export const getAllSubscription = async (query) => {
    try {
        const data = await axios.get(`${API_URL}/subs/all-subscription?${query}`)
            .then(res => {
                console.log(res);
                return (res.data);
            })
        return data;
    }
    catch (err) {
        console.log(err?.response?.data);
        return err?.response?.data;
    }
}

// Get All Subscription
export const getAllPayments = async (userId) => {
    try {
        const data = await axios.get(`${API_URL}/subs/payment/user/${userId}`)
            .then(res => {
                console.log(res);
                return (res.data);
            })
        return data;
    }
    catch (err) {
        console.log(err?.response?.data);
        return err?.response?.data;
    }
}

// Get User Subscription
export const getSubscription = async (userID) => {
    try {
        const data = await axios.get(`${API_URL}/subs/subscription/${userID}`)
            .then(res => {
                console.log(res);
                return (res.data.message);
            })
        return data;
    }
    catch (err) {
        console.log(err?.response?.data);
        return err?.response?.data;
    }
}


// global-metrics/quotes/latest
export const globalMetrics = async () => {
    try {
        const data = await axios.get(`${API_URL}/global-metrics/quotes/latest`)
            .then(res => {
                console.log(res);
                return (res.data);
            })
        return data;
    }
    catch (err) {
        console.log(err.response.data);
        return err.response.data;
    }
}


// btc-price
export const btcPrice = async () => {
    try {
        const data = await axios.get(`${API_URL}/btc-price`)
            .then(res => {
                console.log(res);
                return (res.data);
            })
        return data;
    }
    catch (err) {
        console.log(err.response.data);
        return err.response.data;
    }
}


// eth-price
export const ethPrice = async () => {
    try {
        const data = await axios.get(`${API_URL}/eth-price`)
            .then(res => {
                console.log(res);
                return (res.data);
            })
        return data;
    }
    catch (err) {
        console.log(err.response.data);
        return err.response.data;
    }
}


// Stripe
export const stripeCall = async (body) => {
    try {
        const data = await axios.post(`${API_URL}/stripe/create`, body)
            .then(res => {
                console.log(res);
                return (res.data);
            })
        return data;
    }
    catch (err) {
        console.log(err.response.data);
        return err.response.data;
    }
}


// activate and deactivate user
export const changeUserStatus = async (body) => {
    try {
        const data = await axios.get(`${API_URL}/auth/user-status/${body.id}/${body.status}`)
            .then(res => {
                console.log(res);
                return (res.data);
            })
        return data;
    }
    catch (err) {
        console.log(err.response.data);
        return err.response.data;
    }
}


// activate subscription
export const activateSubscription = async (body) => {
    try {
        const data = await axios.post(`${API_URL}/subs/activate-subs`, body)
            .then(res => {
                console.log(res);
                return (res.data);
            })
        return data;
    }
    catch (err) {
        console.log(err.response.data);
        return err.response.data;
    }
}


// deactivate subscription
export const deactivateSubscription = async (body) => {
    try {
        const data = await axios.post(`${API_URL}/subs/deactivate-subs`, body)
            .then(res => {
                console.log(res);
                return (res.data);
            })
        return data;
    }
    catch (err) {
        console.log(err.response.data);
        return err.response.data;
    }
}


export const getPortfolio = async (userID) => {
    try {
        const data = await axios.get(`${API_URL}/portfolio/get-new`, {
            params: {
                id: userID
            }
        })
            .then(res => {
                console.log(res);
                return (res.data);
            })
        return data;
    }
    catch (err) {
        console.log(err.response.data);
        return err.response.data;
    }
}

export const getPositions = async (userID) => {
    try {
        const data = await axios.get(`${API_URL}/portfolio/get-positions`, {
            params: {
                id: userID
            }
        })
            .then(res => {
                console.log(res);
                return (res.data);
            })
        return data;
    }
    catch (err) {
        console.log(err.response.data);
        return err.response.data;
    }
}


export const TX_Coin_User = async (body) => {
    try {
        const data = await axios.post(`${API_URL}/portfolio/coin-tx-by-user`, body)
            .then(res => {
                console.log(res);
                return (res.data);
            })
        return data;
    }
    catch (err) {
        console.log(err.response.data);
        return err.response.data;
    }
}


export const DeleteThisCoin = async (coin, user) => {
    try {
        const data = await axios.post(`${API_URL}/portfolio/delete-coin-tx-by-user`, {
            userID: user,
            coin,
        })
            .then(res => {
                console.log(res);
                return (res.data);
            })
        return data;
    }
    catch (err) {
        console.log(err.response.data);
        return err.response.data;
    }
}


export const addPortfolio = async (body) => {
    try {
        const data = await axios.post(`${API_URL}/portfolio/add`, body)
            .then(res => {
                console.log(res);
                return (res.data);
            })
        return data;
    }
    catch (err) {
        console.log(err.response.data);
        return err.response.data;
    }
}
export const addPosition = async (body) => {
    try {
        const data = await axios.post(`${API_URL}/portfolio/add-position`, body)
            .then(res => {
                console.log(res);
                return (res.data);
            })
        return data;
    }
    catch (err) {
        console.log(err.response.data);
        return err.response.data;
    }
}


export const addPortfolioWallet = async (body) => {
    try {
        const data = await axios.post(`${API_URL}/portfolio/add-wallet`, body)
            .then(res => {
                console.log(res);
                return (res.data);
            })
        return data;
    }
    catch (err) {
        console.log(err.response.data);
        return err.response.data;
    }
}


export const getWalletByUserId = async (userID) => {
    try {
        const data = await axios.get(`${API_URL}/portfolio/get-wallet-by-user`, {
            params: {
                id: userID
            }
        })
            .then(res => {
                console.log(res);
                return (res.data);
            })
        return data;
    }
    catch (err) {
        console.log(err.response.data);
        return err.response.data;
    }
}


export const getPortfolioBuyWallet = async (wallet) => {
    try {
        const data = await axios.get(`${API_URL}/portfolio/get-portfolio-by-wallet`, {
            params: {
                wallet: wallet
            }
        })
            .then(res => {
                console.log(res);
                return (res.data);
            })
        return data;
    }
    catch (err) {
        console.log(err.response.data);
        return err.response.data;
    }
}


export const getLatestRates = async () => {
    try {
        const data = await axios.get(`${API_URL}/portfolio/listings/latest`)
            .then(res => {
                console.log(res);
                return (res.data);
            })
        return data;
    }
    catch (err) {
        console.log(err.response.data);
        return err.response.data;
    }
}


export const getLatestQuotes = async () => {
    try {
        const data = await axios.get(`${API_URL}/portfolio/quotes/latest`)
            .then(res => {
                console.log(res);
                return (res.data);
            })
        return data;
    }
    catch (err) {
        console.log(err.response.data);
        return err.response.data;
    }
}


// Get all Creators name List
export const getAllCreators = async () => {
    try {
        const data = await axios.get(`${API_URL}/signal/get-all-creators`)
            .then(res => {
                console.log(res);
                return (res.data);
            })
        return data;
    }
    catch (err) {
        console.log(err.response.data);
        return err.response.data;
    }
}


// Set User as Admin
export const setAsAdmin = async (body) => {
    try {
        const data = await axios.post(`${API_URL}/auth/set-as-admin`, body)
            .then(res => {
                console.log(res);
                return (res.data);
            })
        return data;
    }
    catch (err) {
        console.log(err.response.data);
        return err.response.data;
    }
}


// monthly-report
export const getMonthlyReport = async () => {
    try {
        const data = await axios.get(`${API_URL}/signal/monthly-report`)
            .then(res => {
                console.log(res);
                return (res.data);
            })
        return data;
    }
    catch (err) {
        console.log(err.response.data);
        return err.response.data;
    }
}


// complete-report
export const getCompleteReport = async () => {
    try {
        const data = await axios.get(`${API_URL}/signal/complete-report`)
            .then(res => {
                console.log(res);
                return (res.data);
            })
        return data;
    }
    catch (err) {
        console.log(err.response.data);
        return err.response.data;
    }
}


// complete-report
export const semdEmailsToAll = async (body) => {
    try {
        const data = await axios.post(`${API_URL}/email/send-to-all`, body)
            .then(res => {
                console.log(res);
                return (res.data);
            })
        return data;
    }
    catch (err) {
        console.log(err.response.data);
        return err.response.data;
    }
}