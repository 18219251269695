import Table from 'react-bootstrap/Table';
import Dropdown from 'react-bootstrap/Dropdown';
import EditSignal from './editSignal';
import { deleteSignal, getSignal } from '../../helpers/apis';
import { useEffect, useState } from 'react';
import { BiTrash } from 'react-icons/bi';
import { Paginate } from '../pagination';


function Signal() {
    const urlParams = new URLSearchParams(window.location.search);
    const thisPage = urlParams.get('page');

    const [totalItems, setTotalItems] = useState(0)
    const [perPage, setPerPage] = useState(0)
    const [data, setData] = useState([]);

    const GetSignal = async (query) => {
        const res = await getSignal({}, query);
        setData(res.message)
        setTotalItems(res.totalSignals)
        setPerPage(res.perPage)
    }

    const DeleteThisSignal = async (id) => {
        if (window.confirm("Are you sure want to delete this signal?")) {
            const res = await deleteSignal(id);
            if (res.success) {
                GetSignal()
            }
            alert(res.message)
        }
    }

    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        maximumFractionDigits: 2, // (causes 2500.99 to be printed as $2,501)
    });


    useEffect(() => {

        GetSignal(localStorage.getItem("lastPage") == window.location.hash ? `page=${thisPage}` : `page=${1}`)
        localStorage.setItem("lastPage", window.location.hash)

    }, [thisPage])


    return (
        <>
            <section className='signal-table'>
                <Table hover responsive>
                    <thead>
                        <tr>
                            <th>Crypto</th>
                            <th>Status</th>
                            <th>Trade Type</th>
                            <th>Entry Date</th>
                            <th>Entry Price</th>
                            <th>Stop</th>
                            <th>Target</th>
                            <th>Portfolio % to be applied</th>
                            <th>Price</th>
                            <th>Exit Date</th>
                            <th>Creator</th>
                            <th>Profit%</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            data?.map((item, i) => {
                                return <tr key={item._id}>
                                    <td> {item.Crypto}</td>
                                    <td> {item.status}</td>
                                    <td> {item.Long_Short}</td>
                                    <td> {item.Entry_Date}</td>
                                    <td> {!isNaN(item.Average_Price) ? formatter.format(item.Average_Price) : item.Average_Price}</td>
                                    <td> {!isNaN(item.Stop) ? formatter.format(item.Stop) : item.Stop}</td>
                                    <td> {!isNaN(item.Target) ? formatter.format(item.Target) : item.Target}</td>
                                    <td className="text-success" style={{ fontWeight: 700 }}> {item.Recommended_Portfolio}</td>
                                    <td>{(typeof item.Price != "undefined" && item.Price != "" && !isNaN(item.Target)) ? formatter.format(item.Price) : "---"}</td>
                                    <td>{(typeof item.Exit_Date != "undefined" && item.Exit_Date != "") ? item.Exit_Date : "---"}</td>
                                    <td>{(typeof item.creator != "undefined" && item.creator != "") ? item.creator : "---"}</td>
                                    <td>{(typeof item.Profit != "undefined" && item.Profit != "" && !isNaN(item.Profit)) ? `${formatter.format(item.Profit)}%` : "---"}</td>
                                    <td>
                                        <EditSignal id={item._id} allSignals={GetSignal} />
                                        <button className='btn' onClick={() => DeleteThisSignal(item._id)} style={{ cursor: 'pointer' }} > <BiTrash /> </button>
                                    </td>
                                </tr>
                            })
                        }
                    </tbody>
                </Table>
            </section>

            <Paginate totalItems={totalItems} itemsPerPage={perPage} redirectTo={""} />
        </>
    );
}

export default Signal;