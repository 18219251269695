import React, { useEffect, useState } from 'react'


export default function CoinmarketcapWidgetMarquee() {
    useEffect(() => {
        const widgetContainer = document.getElementById("coinmarket-widget");
        const widget = document.getElementById("coinmarketcap-widget-marquee");
        widgetContainer.appendChild(widget);

    }, []);

    return (
        <div className="card">
            <div id="coinmarket-widget" style={{ position: "fixed", bottom: "0px", zIndex: "10000000000", width: "100%"}} />
        </div>
    );
};