import { useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import { useAuth } from "../../ContextAPI/Components/auth";
import { Navbar } from "../navbar"
import { Footer } from '../footer';
import { useNavigate } from "react-router-dom";
import { ProfitBooked } from "./profitBooked"
import { ONGOING_SIGNALS } from "./ongoingSignals"
import { Post } from "./post"
import { getSignal, getPost } from "../../helpers/apis";

import { CurrencyWidget } from "./currency-widget";
import STRIPE from "../stripe";
import moment from "moment";
import { isUserSubscriptionActive } from "../../helpers/isUserSubscriptionActive";

import Card1 from "../../assets/NEW/card1.png"
import Card2 from "../../assets/NEW/card2.png"
import Card3 from "../../assets/NEW/card3.png"
import PaymentGateway from "../payment/payment";



export const VerifiedInvestment = ({ notify, toast, getUser, socket }) => {
    const navigate = useNavigate()
    const { user } = useAuth()

    const [authToken, setAuthToken] = useState(window.localStorage.getItem("token"))
    const [active, setActive] = useState("#ONGOING_SIGNALS")
    const [post, setPost] = useState([]);
    const [privatePost, setPrivatePost] = useState([]);
    const [data, setData] = useState([]);
    const [data2, setData2] = useState([]);


    const GetOngoingSignal = async () => {
        const res = await getSignal({ status: "ongoing" });
        setData(res.message)
    }

    const GetEndedSignal = async () => {
        const res = await getSignal({ status: "ended" });
        setData2(res.message)
    }

    const GetPost = async () => {
        const res = await getPost();
        console.log('res', res)
        const filteredPosts = res.message.filter(post => post.YoutubeType !== "private");
        setPrivatePost(res.message.filter(post => post.YoutubeType == "private"));
        setPost(filteredPosts);
        // setPost(res.message)
    }

    useEffect(() => {
        if (authToken == "" || authToken == null || typeof authToken == "undefined") {
            notify("You need to login first", toast.error)
            navigate("/")
        }
        GetOngoingSignal()
        GetEndedSignal()
        GetPost()


    }, [])

    const { hash } = useLocation();
    useEffect(() => {
        console.log(hash);
        setActive(hash || "#ONGOING_SIGNALS")

    }, [hash])




    return <>
        <div className='landingPage2'>
            <Navbar />
        </div>

        <CurrencyWidget socket={socket} />


        {
            user == null ?
                <div className="spinner-border" role="status">
                    <span className="sr-only">Loading...</span>
                </div>
                :
                isUserSubscriptionActive(user) ?
                    <>
                        <div className="vi-tab-sec">
                            <button className={`${active == "#ONGOING_SIGNALS" && "active"} vi-tab`} onClick={() => navigate(`#ONGOING_SIGNALS`)}>ONGOING SIGNALS</button>
                            <button className={`${active == "#UPDATES" && "active"} vi-tab`} onClick={() => navigate(`#UPDATES`)}>UPDATES</button>
                            <button className={`${active == "#PROFIT_BOOKED" && "active"} vi-tab`} onClick={() => navigate(`#PROFIT_BOOKED`)}>PROFIT BOOKED</button>
                            <button className={`${active == "#UNLISTED" && "active"} vi-tab`} onClick={() => navigate(`#UNLISTED`)}>GUIDES</button>
                        </div>

                        <div className="vi">
                            <div className="container">
                                <div className="row">
                                    <div className="mx-auto p-3 mb-5">
                                        {
                                            active == "#ONGOING_SIGNALS" ? <ONGOING_SIGNALS data={data} />
                                                :
                                                active == "#UPDATES" ?
                                                    <div className="d-flex justify-content-center flex-wrap">
                                                        {
                                                            post.map((item, i) => {
                                                                return <Post key={i}
                                                                    profile={typeof item.userID != "undefined" && typeof item.userID.profile != "undefined" && item.userID.profile != "" ? item.userID.profile : ""}
                                                                    username={item.userID.username}
                                                                    timepass={item.timestamp}
                                                                    time={item.timestamp}
                                                                    date={item.timestamp}
                                                                    Post={typeof item.Post != "undefined" && item.Post != "" ? item.Post : ""}
                                                                    Poster={typeof item.Poster != "undefined" && item.Poster != "" ? item.Poster : ""}
                                                                    PostType={typeof item.PostType != "undefined" && item.PostType != "" ? item.PostType : ""}
                                                                    tweet={typeof item.tweet != "undefined" && item.tweet != "" ? item.tweet : ""}
                                                                />
                                                            })
                                                        }
                                                    </div>
                                                    :
                                                    active == "#UNLISTED" ?
                                                        <div className="d-flex justify-content-center flex-wrap">
                                                            {
                                                                privatePost.map((item, i) => {
                                                                    return <Post key={i}
                                                                        profile={typeof item.userID != "undefined" && typeof item.userID.profile != "undefined" && item.userID.profile != "" ? item.userID.profile : ""}
                                                                        username={item.userID.username}
                                                                        timepass={item.timestamp}
                                                                        time={item.timestamp}
                                                                        date={item.timestamp}
                                                                        Post={typeof item.Post != "undefined" && item.Post != "" ? item.Post : ""}
                                                                        Poster={typeof item.Poster != "undefined" && item.Poster != "" ? item.Poster : ""}
                                                                        PostType={typeof item.PostType != "undefined" && item.PostType != "" ? item.PostType : ""}
                                                                        tweet={typeof item.tweet != "undefined" && item.tweet != "" ? item.tweet : ""}
                                                                    />
                                                                })
                                                            }
                                                        </div>
                                                        :
                                                        active == "#PROFIT_BOOKED" && <ProfitBooked data={data2} />
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                    :
                    <>
                        <div className="sec4">
                            <h1 className="mt-5">You are not <span className="activeLine">subscribed</span>. To subscribe</h1>
                        </div>


                        <div className="container" style={{ position: "relative" }}>
                            <div className="row" style={{ justifyContent: "center" }}>
                                <div className="col-lg-4 col-md-6 col-sm-12 mb-3">
                                    <div className="cardsec">
                                        <div className="card">
                                            <div className='img-card'>
                                                <img src={Card1} className="card-img-top" alt="img" />
                                            </div>
                                            <div className="card-body">
                                                <p className="card-title text-center" style={{ fontWeight: "500", color: "var(--textSecondColor)", fontWeight: 600 }} >MONTHLY PLAN</p>
                                                <h2 className="card-title text-center" style={{ fontWeight: "700" }}>30 USD</h2>
                                                <p className="card-text text-center" style={{ minHeight: "70px" }}>Choose our monthly for flexibility and pay on a month-to-month basis</p>
                                                {/* <STRIPE text="Subscribe Now" class="btn first mx-auto" user={user?.id} planType="monthly" /> */}
                                                <PaymentGateway class="btn green_button mx-auto" user={user?.id} planType="monthly" planAmount="30" notify={notify} toast={toast} socket={socket} />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-4 col-md-6 col-sm-12 mb-3">
                                    <div className="cardsec">
                                        <div className="card">
                                            <div className='img-card'>
                                                <img src={Card3} className="card-img-top" alt="img" />
                                            </div>
                                            <div className="card-body">
                                                <p className="card-title text-center" style={{ fontWeight: "500", color: "var(--textSecondColor)", fontWeight: 600 }} >YEARLY PLAN</p>
                                                <h2 className="card-title text-center" style={{ fontWeight: "700" }}>300 USD</h2>
                                                <p className="card-text text-center" style={{ minHeight: "70px" }}>Opt for our yearly plan to enjoy savings with a single payment for the entire year</p>
                                                {/* <STRIPE text="Subscribe Now" class="btn second mx-auto" user={user?.id} planType="yearly" /> */}
                                                <PaymentGateway class="btn green_button mx-auto" user={user?.id} planType="yearly" planAmount="300" notify={notify} toast={toast} socket={socket} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
        }

        <Footer />
    </>
}
