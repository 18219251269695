import { createContext, useContext, useState, useEffect } from "react"
import { getSubscription, getUserData } from "../../helpers/apis";



// Step 1
const AuthContext = createContext()

// Step 2
export const useAuth = () => {
    return useContext(AuthContext);
}

// Step 3
export const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(null);

    async function subs(userID) {
        const data = await getSubscription(userID);
        console.log("getSubscription");
        console.log(data);

        setUser({ ...user, subscription: data })
    }

    async function UserData() {
        const _user = await getUserData()
        if (_user.success) setUser(_user.message)

        // const token = window.localStorage.getItem("token");
        // if (token != null && token != "") {
        //     const _user = await getUserData(token)
        //     if (_user.success) setUser(_user.message)
        // }

    }


    useEffect(() => {
        const token = window.localStorage.getItem("token");
        if (user == null && token != null && token != "") UserData()

        console.log("User", user);

    }, [user])



    return (
        <AuthContext.Provider value={{ user, setUser, UserData, subs }}>
            {children}
        </AuthContext.Provider>
    )
}