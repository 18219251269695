
export function ONGOING_SIGNALS(props) {
    const Ongoing_Signals = ["CRYPTO", "TRADE TYPE", "ENTRY DATE", "ENTRY PRICE", "STOP", "TARGET", "Portfolio % to be applied"];

    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        maximumFractionDigits: 2, // (causes 2500.99 to be printed as $2,501)
    });


    return <>
        <div className="table-responsive">
            <table className="table align-middle">
                <thead>
                    <tr>
                        {
                            (Ongoing_Signals).length > 0 && (Ongoing_Signals).map((item, i) => {
                                return <th key={i} scope="col">{item}</th>
                            })
                        }
                    </tr>
                </thead>
                <tbody>
                    {
                        (typeof props.data != "undefined") && (props.data).length > 0 && (props.data).map(item => {
                            return <tr key={item._id}>
                                <td>{item.Crypto}</td>
                                <td>{item.Long_Short}</td>
                                <td>{item.Entry_Date}</td>
                                <td>{!isNaN(item.Average_Price) ? formatter.format(item.Average_Price) : item.Average_Price}</td>
                                <td>{!isNaN(item.Stop) ? formatter.format(item.Stop) : item.Stop}</td>
                                <td>{formatter.format(item.Target)}</td>
                                <td className="text-success" style={{ fontWeight: 700 }}>{item.Recommended_Portfolio}</td>
                            </tr>
                        })
                    }
                </tbody>
            </table>
        </div>
    </>
}