import React, { useState } from 'react';
import { FaRegCopy } from "react-icons/fa6";

import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';


export const CopyToClipboardButton = ({ text }) => {
    const [copied, setCopied] = useState(false);

    const handleCopy = async () => {
        try {
            await navigator.clipboard.writeText(text);
            setCopied(true);
            setTimeout(() => setCopied(false), 2000); // Reset copied state after 2 seconds
        } catch (err) {
            console.error('Failed to copy text: ', err);
        }
    };

    return (
        <div>


            <OverlayTrigger key={"top"} placement={"top"}
                overlay={
                    <Tooltip id={`tooltip-top`}>
                        {
                            copied ?
                                "Copied!"
                                :
                                "Copy Address"
                        }
                    </Tooltip>
                }>

                <span onClick={handleCopy} style={{ cursor: 'pointer' }}>
                    <FaRegCopy />
                </span>

            </OverlayTrigger>

        </div>
    );
};